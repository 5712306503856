import React from 'react';
import Split from "react-split";
import { connect } from 'react-redux';
import {saveUserQuestionsSummary} from '../../store/actions/solgressAction';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import {searchTableHeaderCellCSS,clickableSearchTableBodyCellTextCSS} from './../../constants/TextSizeConstants';
import {currentURLHost} from './../../constants/hostConfig';
import QuestionsReceiver from '../../apis/QuestionsReceiver';
import { JSXUtils } from '../../utils/JSXUtils';
import ClipLoader from "react-spinners/ClipLoader";
import EdforcesHeader from '../header/EdforcesHeader';

const mapDispatchToProps = dispatch => ({
    saveUserQuestionsSummary: (payload) => dispatch(saveUserQuestionsSummary(payload))
})


const mapStateToProps = state => {
    return {
        userQuestionsSummary: state.solgressReducer.userQuestionsSummary
    };
}

class UserQuestionSubmissionsSummary extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
        this.initializeQuestions = this.initializeQuestions.bind(this);
    }

    initializeQuestions = async () => {

        const userDetails = window.sessionStorage.userDetails;
        let userEmail = JSON.parse(userDetails).email;
        await QuestionsReceiver.getUserSubmmittedQuestionsSummary(userEmail).then(questionsSummaryData=>{
            this.props.saveUserQuestionsSummary(questionsSummaryData.data);
        });
    }

    getQuestionsSummaryTableHeaderJSX = () => {
        return <TableRow className = 'bg-gray-100'>
            <TableCell className='border border-slate-300'>
                <p className={searchTableHeaderCellCSS}>
                    Solved Questions
                </p>
            </TableCell>
            <TableCell className='border border-slate-300'>
                <p className={"justify-center" + searchTableHeaderCellCSS}>
                    Correctness
                </p>
            </TableCell>
        </TableRow>;
    }
   
    redirectToQuestionViewInSameTab = (questionId) => {
        window.location.href = currentURLHost + 'question/view?question_id=' + questionId; 
    }

    redirectToQuestionSubmissionViewInSameTab = (questionResponseId) => {
        window.location.href = currentURLHost + 'question/submission/view?response_id=' + questionResponseId; 
    }

    getQuestionsSummaryTableBodyRowsJSX = () => {
        let tableRows = [];
        if(this.props.userQuestionsSummary.length===0) {
            let noTableFoundRow =<TableRow className="hover:bg-slate-100">
                <TableCell>
                    <p className="flex justify-center text-xl">                    
                        No Solved Question Found.
                    </p>
                </TableCell>
            </TableRow>
            tableRows.push(noTableFoundRow);
        }
        else {
            for(let i=0; i<this.props.userQuestionsSummary.length; i++) {
                let questionSummary = this.props.userQuestionsSummary[i];
                let tableRow = <TableRow className="hover:bg-slate-100">
                    <TableCell>
                        <div className={clickableSearchTableBodyCellTextCSS + ' text-xs md:text-sm xl:text-lg'}
                            onClick = {()=>this.redirectToQuestionViewInSameTab(questionSummary.questionId)}
                        >
                            <div dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(questionSummary.questionDescription)}}></div>
                        </div>
                    </TableCell>
                    <TableCell className={questionSummary.correctSubmission?'bg-green-300':'bg-red-300'}>
                        <div className={clickableSearchTableBodyCellTextCSS + ' text-xs md:text-sm xl:text-lg'}
                            onClick = {()=>this.redirectToQuestionSubmissionViewInSameTab(questionSummary.responseId)}>
                            {questionSummary.correctSubmission?'Correct':'Incorrect'}
                        </div>
                    </TableCell>
                </TableRow>;
                tableRows.push(tableRow);
            }
        }
        return tableRows;
    }

    render() {
        if(typeof window == `undefined`){
            return <div/>;
        }
        if(this.props.userQuestionsSummary === undefined){
            this.initializeQuestions();
            return <div>
                <div className='bg-white '>
                    <EdforcesHeader/>
                    <div className='py-20' style={{minHeight: window.innerHeight}}>
                        <ClipLoader color="blue" size="100"/>
                    </div>
                </div>
            </div>
        }
        return <div  style={{minHeight: window.innerHeight*0.95}}>
            <EdforcesHeader/>
            <div className = "flex flex-row...">
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%] sm:min-w-[20%] sm:max-w-[20%]'>
                    {/* Position for ads */}
                </div>
                <div className="w-full  shadow bg-white  overflow-y-scroll ">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                {this.getQuestionsSummaryTableHeaderJSX()}
                            </TableHead>
                            <TableBody>
                                {this.getQuestionsSummaryTableBodyRowsJSX()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%]'>
                    {/* Position for ads */}
                </div>
            </div>
        </div> 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserQuestionSubmissionsSummary);