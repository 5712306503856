import React from 'react';
import { connect } from 'react-redux';
import {updateNewTagDetails } from '../../store/actions/solgressAction';
import TagReceiver from '../../apis/TagReceiver';
import { generalTextSize } from '../../constants/TextSizeConstants';
import { tagPrefixPairMap } from '../../constants/tagPrefixPairMap';
import ClipLoader from "react-spinners/ClipLoader";
import EdforcesHeader from '../header/EdforcesHeader';

const mapDispatchToProps = dispatch => ({
    updateNewTagDetails: (payload) => dispatch(updateNewTagDetails(payload))
})


const mapStateToProps = state => {
    return {
        newTagDetails: state.solgressReducer.newTagDetails
    }
}

class NewTagCreation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
        this.initializeNewTagDetails = this.initializeNewTagDetails.bind(this)
    }

    initializeNewTagDetails = () => {
        let payload = {
            "tagName" : "",
            "tagDescription" : "",
            "tagPrefix" : "Subject : "
        }
        this.props.updateNewTagDetails(payload)
    }

    updateTagName = (event) => {
        let payload = {...this.props.newTagDetails}
        payload.tagName = (event.target.value)
        this.props.updateNewTagDetails(payload)
    }

    updateTagDescription = (event) => {
        let payload = {...this.props.newTagDetails}
        payload.tagDescription = event.target.value
        this.props.updateNewTagDetails(payload)
    }

    submitNewTag = () => {
        let payload ={...this.props.newTagDetails}
        payload.tagName =(payload.tagPrefix+payload.tagName)
        this.props.updateNewTagDetails(payload)
        TagReceiver.upsertNewTag(payload).then(tagData=>{
            alert("Updated the question details")
         })
    }
    updateTagPrefix = (event) => {
        let payload ={...this.props.newTagDetails}
        for(let index=0;index<tagPrefixPairMap.length;index++){
            if(tagPrefixPairMap[index].prefix == event.target.value){
                payload.tagPrefix=tagPrefixPairMap[index].prefix
            }
        }
        this.props.updateNewTagDetails(payload)
    }

    showTagPrefix = () => {
        return (
            <div className="flex flex-row ... px-4 pt-1 ">
                <div className='pr-10'>
                    <div className={generalTextSize + " leading-tight text-gray-800 py-1"}>
                        Tag Type
                    </div>
                </div>
                <select className={generalTextSize + " border bg-white rounded px-3  outline-none w-fit"}
                    value={this.props.newTagDetails.tagPrefix}
                    onChange={(event => this.updateTagPrefix(event))}
                >
                    {tagPrefixPairMap.map((option) => (

                        <option  value={option.prefix}>
                            {option.inputPlaceholder} 
                        </option>

                    ))}
                </select>
            </div>
        )
    }
    render() {
        if(typeof window == `undefined`){
            return <div/>
        }
        if(this.props.newTagDetails === undefined){
            this.initializeNewTagDetails()
            return <div>
                <div className='bg-white '>
                    <EdforcesHeader/>
                    <div className='py-20' style={{minHeight: window.innerHeight}}>
                        <ClipLoader color="blue" size="100"/>
                    </div>
                </div>
            </div>
        }
        return <div  style={{minHeight: window.innerHeight*0.95}}>
            <EdforcesHeader/>
            <div className = "flex flex-row">
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%] sm:min-w-[20%] sm:max-w-[20%]'>
                    {/* Position for ads */}
                </div>
                <div className='w-full border' style={{minHeight: window.innerHeight*0.85}}>
                    <div className='py-1'>
                        {this.showTagPrefix()}
                    </div>
                    <div class="flex flex-row w-full py-1">
                        <div className='px-4 pr-8'>Tag Name</div>
                        <input className={generalTextSize + "  border border-gray-300 py-1 pl-3 py-1shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent placeholder-gray-500 text-gray-600 "}
                            placeholder="Enter Tag Name" 
                            value = {this.props.newTagDetails.tagName}  
                            onChange = {(event) => this.updateTagName(event)}  
                        />
                    </div>
                    <div class="mt-1 flex flex-row w-full">
                        <div className='px-4 pr-5 py-2'>Description</div>
                        <textarea className="w-1/2 resize bg-transparent border border-gray-300  pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 resize-none placeholder-gray-500 text-gray-600 "
                        placeholder="Provide the description of tag-name for everyone to better understand and reuse it" 
                        value = {this.props.newTagDetails.tagDescription}
                        onChange = {(event) => this.updateTagDescription(event)}
                        rows="5"/>
                    </div>
                    <div  class="mt-2 px-5 flex justify-between">
                        <button className=" my-1fle transition duration-150 ease-in-out rounded  py-1 text-xs bg-blue-500 text-white"
                            onClick={() => this.submitNewTag()}
                        >
                            <p className="text-lg px-3 ... ">
                                Submit
                            </p>
                        </button>
                    </div>
                </div>
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%]'>
                    {/* Position for ads */}
                </div>
            </div>
        </div> 
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(NewTagCreation);
