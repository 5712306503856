import React from 'react';
import Split from "react-split";
import { connect } from 'react-redux';
import {saveUserPapersSummary} from '../../store/actions/solgressAction';
import PaperAPIsConnector from "../../apis/PaperAPIsConnector";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import {searchTableHeaderCellCSS,clickableSearchTableBodyCellTextCSS,nonClickableSearchTableBodyCellTextCSS} from './../../constants/TextSizeConstants';
import {currentURLHost} from './../../constants/hostConfig';
import ClipLoader from "react-spinners/ClipLoader";
import EdforcesHeader from '../header/EdforcesHeader';

const mapDispatchToProps = dispatch => ({
    saveUserPapersSummary: (payload) => dispatch(saveUserPapersSummary(payload))
})


const mapStateToProps = state => {
    return {
        userPapersSummary: state.solgressReducer.userPapersSummary
    };
}

class UserPaperSubmissionsSummary extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
        this.initializePapers = this.initializePapers.bind(this);
    }

    initializePapers = async () => {

        const userDetails = window.sessionStorage.userDetails;
        let userEmail = JSON.parse(userDetails).email;
        await PaperAPIsConnector.getUserSubmmittedPapersSummary(userEmail).then(papersSummaryData=>{
            this.props.saveUserPapersSummary(papersSummaryData.data);
        });
    }

    getPapersSummaryTableHeaderJSX = () => {
        return <TableRow className = 'bg-gray-100'>
            <TableCell className='border border-slate-300'>
                <p className={searchTableHeaderCellCSS}>
                    Papers 
                </p>
            </TableCell>
            <TableCell className='border border-slate-300'>
                <p className={"justify-center" + searchTableHeaderCellCSS}>
                    Submission Date
                </p>
            </TableCell>
            <TableCell className='border border-slate-300'>
                <p className={"justify-center" + searchTableHeaderCellCSS}>
                    Status
                </p>
            </TableCell>
        </TableRow>;
    }
   
    redirectToPaperSubmissionViewInSameTab = (paperId, paperSubmissionId, isPaperSubmissionBlocked) => {
        if(isPaperSubmissionBlocked) {
            window.location.href = currentURLHost + 'paper/submission/view?paper_submission_response_id=' + paperSubmissionId;
        } 
        else {
            window.location.href = currentURLHost + 'paper/view?paper_id=' + paperId + '&paper_instance_id=' + paperSubmissionId;
        }
    }

    getPapersSummaryTableBodyRowsJSX = () => {
        let tableRows = [];
        if(this.props.userPapersSummary.length===0) {
            let noTableFoundRow =<TableRow className="hover:bg-slate-100">
                <TableCell>
                    <p className="flex justify-center text-xl">                    
                        No Solved/Ongoing Paper Found.
                    </p>
                </TableCell>
            </TableRow>
            tableRows.push(noTableFoundRow);
        }
        else {
            for(let i=0; i<this.props.userPapersSummary.length; i++) {
                let paperSummary = this.props.userPapersSummary[i];
                let tableRow = <TableRow className="hover:bg-slate-100">
                    <TableCell>
                        <div className={clickableSearchTableBodyCellTextCSS + ' text-xs md:text-sm xl:text-lg'}
                            onClick = {()=>this.redirectToPaperSubmissionViewInSameTab(paperSummary.paperId, paperSummary.paperSubmissionId,paperSummary.paperSubmissionBlocked)}
                        >
                            {paperSummary.paperName}
                        </div>
                    </TableCell>
                    <TableCell>
                        <div className={nonClickableSearchTableBodyCellTextCSS}>
                            {new Date(parseInt(paperSummary.submissionDate)).toLocaleDateString('en-GB', { day: '2-digit',month: 'short', year: 'numeric'}).replace(/ /g, '-')}
                        </div>
                    </TableCell>
                    <TableCell className={paperSummary.paperSubmissionBlocked?'bg-slate-100':'bg-green-300'}>
                        <div className={nonClickableSearchTableBodyCellTextCSS}>
                            {paperSummary.paperSubmissionBlocked?'Submitted':'Ongoing'}
                        </div>
                    </TableCell>
                </TableRow>;
                tableRows.push(tableRow);
            }
        }
        return tableRows;
    }

    render() {
        if(typeof window == `undefined`){
            return <div/>;
        }
        if(this.props.userPapersSummary === undefined){
            this.initializePapers();
            return <div>
                <div className='bg-white '>
                    <EdforcesHeader/>
                    <div className='py-20' style={{minHeight: window.innerHeight}}>
                        <ClipLoader color="blue" size="100"/>
                    </div>
                </div>
            </div>
        }
        return <div style={{minHeight: window.innerHeight*0.95}}>
            <EdforcesHeader/>
            <div className = "flex flex-row...">
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%] sm:min-w-[20%] sm:max-w-[20%]'>
                    {/* Position for ads */}
                </div>
                <div className="w-full  shadow bg-white  overflow-y-scroll ">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                {this.getPapersSummaryTableHeaderJSX()}
                            </TableHead>
                            <TableBody>
                                {this.getPapersSummaryTableBodyRowsJSX()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%]'>
                    {/* Position for ads */}
                </div>
            </div>
        </div>      
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPaperSubmissionsSummary);