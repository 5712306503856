import React from 'react';
import { connect } from 'react-redux';
import {saveQuestionSet, savePaperSet, updateGeneralInfo} from '../../../store/actions/solgressAction';
import TagReceiver from '../../../apis/TagReceiver';
import { generalTextSize, searchBoxInputCSS, searchTableHeaderCellCSS } from '../../../constants/TextSizeConstants';
import QuestionsReceiver from '../../../apis/QuestionsReceiver'
import PaperAPIsConnector from '../../../apis/PaperAPIsConnector';
import Collapsible from "react-collapsible";
import {AiOutlineDownSquare, AiOutlineClose} from "react-icons/ai";
import {MdArrowDropDown} from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { JSXUtils } from '../../../utils/JSXUtils';
import './leftSideBar.css';
import { AiOutlineSearch } from "react-icons/ai";

const mapDispatchToProps = dispatch => ({
    saveQuestionSet: (payload) => dispatch(saveQuestionSet(payload)),
    savePaperSet: (payload) => dispatch(savePaperSet(payload)),
    updateGeneralInfo: (payload) => dispatch(updateGeneralInfo(payload))
})


const mapStateToProps = state => {
    return {
        questionSet: state.solgressReducer.questionSet,
        paperSet: state.solgressReducer.paperSet,
        generalInfo: state.solgressReducer.generalInfo
    };
}

class TagsFilterViewLarge extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
    }
    addNewTag = (tag,index) => {
        let payload = {...this.props.generalInfo};
        if(this.isASelectedTag(this.props.generalInfo.suggestedTags.get(tag)[index].id)){
            let selectedTags=[];
            for(let i=0;i<payload.selectedTags.length;i++){
                if(payload.selectedTags[i].id != payload.suggestedTags.get(tag)[i].id){
                    selectedTags.push(payload.selectedTags[i]);
                }
            }
            payload.selectedTags= selectedTags;
        }
        else{ 
            let selectedTags = [...payload.selectedTags];
            selectedTags.push(payload.suggestedTags.get(tag)[index]);
            payload.selectedTags=selectedTags;
        }
        this.props.updateGeneralInfo(payload);
        let selectedTagIds=[];
        for(let index=0;index<payload.selectedTags.length;index++){
            selectedTagIds.push(payload.selectedTags[index].id);
        }
        let channelIds=[];
        let searchedKey="";
        QuestionsReceiver.getAllFilteredQuestions(searchedKey, selectedTagIds, channelIds).then(questionsData=>{
            let payload = {...this.props.questionSet};
            payload.questions = questionsData.data;
            payload.searchedKey = searchedKey;
            this.props.saveQuestionSet(payload);
        });
    }
    
    isASelectedTag = (tagId) => {
        let selectedTags=[...this.props.generalInfo.selectedTags];
        for(let index=0;index<selectedTags.length;index++){
            if(selectedTags[index].id == tagId){
                return true;
            }
        }
        return false;
    }

    removeSelectedAppliedTag = (tagId) => {
        let updatedTemporaryTags=[];
        let selectedTags=[...this.props.generalInfo.selectedTags];
        let payload ={...this.props.generalInfo};
        for(let x=0; x<selectedTags.length;x++) {
            if(tagId!=selectedTags[x].id){
                updatedTemporaryTags.push(selectedTags[x]);   
            }
        }
        payload.selectedTags = updatedTemporaryTags;
        this.props.updateGeneralInfo(payload);
        let selectedTagIds=[];
        for(let index=0;index<payload.selectedTags.length;index++){
            selectedTagIds.push(payload.selectedTags[index].id);
        }
        let channelIds=[];
        let searchedKey="";
        QuestionsReceiver.getAllFilteredQuestions(searchedKey, selectedTagIds, channelIds).then(questionsData=>{
            let payload = {...this.props.questionSet};
            payload.questions = questionsData.data;
            payload.searchedKey = searchedKey;
            this.props.saveQuestionSet(payload);
        });
    }

    showSelectedTags = () => {
        if (this.props.generalInfo == undefined || this.props.generalInfo.selectedTags == undefined || this.props.generalInfo.selectedTags.length == 0) {
            return <div></div>;
        }
        let response = [];
        response.push(<div>
            <div className={"px-2  py-2 " + generalTextSize + " leading-tight text-gray-800 "}>
                {/* Filters applied : */}
            </div>
        </div>
        );
        let payload= {...this.props.generalInfo};
        let selectedTags = [...this.props.generalInfo.selectedTags];
        for(let index=0; index<selectedTags.length; index++) {
            if(this.isASelectedTag(selectedTags[index].id) ){
                    response.push(
                        <div className='py-2 pr-3'>
                            <div className="flex flex-row border rounded ...">
                                <div className=" ">
                                    <div className="flex bg-white transition duration-150 ease-in-out rounded text-gray-800  pl-3 pr-1" >
                                        <p className={generalTextSize}>
                                            {selectedTags[index].tagName}
                                        </p>
                                    </div>
                                </div>
                                <div className = 'pt-1 pr-1' onClick={()=>this.removeSelectedAppliedTag(selectedTags[index].id)}>
                                    <AiOutlineClose size={20} color={'blue'}/>
                                </div>
                            </div>
                        </div>
                    );
                        
            }
        }
        return <div className='flex flex-row'>{response}</div>;
    }

    getNormalisedTagName = (tagName, prefix) => {
        return tagName.slice(prefix.length);
    }

    showSuggestedTag = (tag) => {
        let response = [];
        let suggestedTags=this.props.generalInfo.suggestedTags.get(tag);
        for(let index=0; index<suggestedTags.length; index++) {
                response.push(
                    <div className={generalTextSize + " px-3 py-1 border-l border-t border-r hover:bg-blue-200 w-full bg-white"}  onClick={()=>(this.addNewTag(tag,index)                                                                                                                               )}>
                        <div className='flex flex-row w-full'>
                            <div className='px-2 py-1'>{this.getNormalisedTagName(suggestedTags[index].tagName, this.props.generalInfo.tagPrefixPairMap.get(tag).prefix)}</div>
                            <div className='flex grow justify-end w-full pr-2 py-1'>
                                {
                                    this.isASelectedTag(suggestedTags[index].id)
                                        ?<input type='checkbox' checked className='w-4 h-4'></input>
                                        :<input type='checkbox' className='w-4 h-4'></input>
                                }
                            </div>
                        </div>
                    </div>
                    
                );
        }
        return <div className=' flex overflow-auto flex-col w-full '>{response}</div>
    }

    updateTagDetails = (event,tag) => {
        let payload = {...this.props.generalInfo};
        let tagPlaceholder = new Map(payload.tagPlaceholder);
        tagPlaceholder.set(tag, event.target.value);
        payload.tagPlaceholder = tagPlaceholder;
        this.props.updateGeneralInfo(payload);
        let prefixValue =payload.tagPrefixPairMap.get(tag).prefix;
        prefixValue=(prefixValue+event.target.value);
        TagReceiver.getSuggestedTags((prefixValue)).then(tagData=>{
            let payload = {...this.props.generalInfo};
            let suggestedTagsByApi = [...tagData.data];
            let suggestedTags =new Map(payload.suggestedTags);
            suggestedTags.set(tag,suggestedTagsByApi);
            payload.suggestedTags=suggestedTags;
            this.props.updateGeneralInfo(payload);
        });
    }

    activateTagPopup = (index) => {
        let payload = {...this.props.generalInfo};
        if(payload.activeTagFilterPopupIndex == null) {
            payload.activeTagFilterPopupIndex = index;
        }
        else {
            payload.activeTagFilterPopupIndex = null;
        }
        this.props.updateGeneralInfo(payload);
    }

    inactivateTagPopup = () => {
        let payload = {...this.props.generalInfo};
        payload.activeTagFilterPopupIndex = null;
        this.props.updateGeneralInfo(payload);
    }
    
    getTagDetailsJSX = (tag) => {
        let isTagSearchActive = this.props.generalInfo.activeTagFilterPopupIndex==tag;
        let triggerBGColor = isTagSearchActive?"bg-blue-500 text-white":"bg-gray-50";
        let triggerContent =  <div className={'flex flex-row px-3 py-2 ' + triggerBGColor} onClick = {(event)=>this.activateTagPopup(tag)}>
            <div className={ generalTextSize }>
                {this.props.generalInfo.tagPrefixPairMap.get(tag).inputPlaceholder}
            </div>
            <div className='flex justify-end'>
                <MdArrowDropDown size={25}/>
            </div>
        </div>

        let content = <div className=' flex flex-col border '  onMouseLeave ={this.inactivateTagPopup}>
                <div className=' flex flex-row '>
                    <div className='lex flex-row bg-white border w-full'>
                        <div className='flex'>
                            <AiOutlineSearch size={40} color={"blue"} className = ' px-2'></AiOutlineSearch>
                            <input
                                type="text"  
                                className = { searchBoxInputCSS + "w-full focus:outline-none rounded py-2 px-2"}
                                placeholder="Search here ..."
                                value = {this.props.generalInfo.tagPlaceholder.get(tag)}  
                                onChange = {(event) => this.updateTagDetails(event,tag)}  
                            />
                        </div>
                    </div>
                </div>
                <div className=''>
                    {this.showSuggestedTag(tag)}
                </div>
            </div>;
        return <Popover
                isOpen={isTagSearchActive}
                positions={['bottom', 'left', 'right', 'up']} // preferred positions by priority
                align = {"start"}
                content={({ position, childRect, popoverRect }) => (
                    content
                )}
                // onClickOutside = {() => this.toggleLoginPopOver()}  
            >
                {triggerContent}
            </Popover>
    }

    initializeGeneralInfo = () => {
        let tagPrefixPairMap = new Map();
        tagPrefixPairMap.set("author", { prefix: "Created By : ", tag: "authorTag" , inputPlaceholder: "Author Name" ,searchPlaceholder:"Search Authors"});
        tagPrefixPairMap.set("subject", { prefix: "Subject : ", tag: "subjectTag", inputPlaceholder: "Subject Name" ,searchPlaceholder:"Search Subjects"});
        tagPrefixPairMap.set("topic", { prefix: "Topic : ", tag: "topicTag", inputPlaceholder: "Topic Name" ,searchPlaceholder:"Search Topics"});
        tagPrefixPairMap.set("exam", { prefix: "Exam Name : ", tag: "examTag", inputPlaceholder: "Exam Name" ,searchPlaceholder:"Search exams"});
        tagPrefixPairMap.set("other", { prefix: "", tag: "otherTag", inputPlaceholder: "other tags" ,searchPlaceholder:"Search other tags"});
       
        const tagPlaceholder = new Map();
        tagPlaceholder.set("author", '');
        tagPlaceholder.set("subject", '');
        tagPlaceholder.set("topic", '');
        tagPlaceholder.set("exam", '');

        let suggestedTags= new Map();
        suggestedTags.set("author",[]);
        suggestedTags.set("subject",[]);
        suggestedTags.set("topic",[]);
        suggestedTags.set("exam",[]);

        let payload ={...this.props.generalInfo};
        payload.suggestedTags= suggestedTags;
        payload.selectedTags=[];
        payload.tagPrefixPairMap = tagPrefixPairMap;
        payload.tagPlaceholder=tagPlaceholder;
        this.props.updateGeneralInfo(payload);
        const allTagNames = ["author","subject","topic","exam"];
        for(let index=0;index<4;index++){
            let tag=allTagNames[index];
            let prefixValue =tagPrefixPairMap.get(tag).prefix;
            prefixValue=(prefixValue);
            TagReceiver.getSuggestedTags((prefixValue)).then(tagData=>{
                let payload ={...this.props.generalInfo};
                let suggestedTagsByApi = [...tagData.data];
                let suggestedTags =new Map(payload.suggestedTags);
                suggestedTags.set(tag,suggestedTagsByApi);
                payload.suggestedTags=suggestedTags;
                this.props.updateGeneralInfo(payload);
            });
        }
        
        
    }

    // updateExistingTags = () => {
    //     let payload = {...this.props.generalInfo};
    //     payload.selectedTags = [...payload.selectedTags];
    //     this.props.updateGeneralInfo(payload);
    // } 
    
    toggleFilterPopOver = () =>{
        let generalInfo = typeof this.props.generalInfo === "undefined"?{}:{...this.props.generalInfo};
        generalInfo.isTagFilterViewActive = generalInfo.isTagFilterViewActive==undefined?true:!generalInfo.isTagFilterViewActive;
        this.props.updateGeneralInfo(generalInfo);
    }

    render() {
        if(this.props.generalInfo == undefined || this.props.generalInfo.tagPrefixPairMap == undefined || this.props.generalInfo.selectedTags ==undefined  || this.props.generalInfo.suggestedTags == undefined) {
            {this.initializeGeneralInfo()};
            return <div></div>;
        }
        // if(this.props.generalInfo.selectedTags.length==0 && this.props.generalInfo.selectedTags.length!=0){
        //     this.updateExistingTags();
        //     return <div/>
        // }
        return <div  className=''>
            <div className="flex flex-row w-full pt-2 ">
                <div className='pl-3 py-2 pr-2'><VscSettings size={25} color={'blue'}/></div>
                <div className={'pr-4 py-2 ' + generalTextSize}>Filters :</div>
                {this.getTagDetailsJSX("author")}
                {this.getTagDetailsJSX("subject")} 
                {this.getTagDetailsJSX("topic")}
                {this.getTagDetailsJSX("exam")} 
                <div className='flex justify-end grow'>
                    <div className='pt-1 px-2 bg-gray-50'>
                        <div className={'flex flex-row  px-3 py-1 '}>
                            <button className={searchTableHeaderCellCSS + (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions ? "" : " text-bold ")} >
                                <div className='flex flex-row'>
                                    <div className='text-xs text-yellow-500 py-1'>
                                        (upcoming)
                                    </div>
                                    <div className='px-2'>
                                        Sort By
                                    </div>
                                </div>
                            </button>
                            <div className='flex justify-end'>
                            <MdArrowDropDown size={25}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.showSelectedTags()}
            <div className='pb-2'></div>
        </div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsFilterViewLarge);
