import React from 'react';
import '../../../App.css';
import {JSXUtils} from "../../../utils/JSXUtils";
import {generalTextSize} from '../../../constants/TextSizeConstants';
import Collapsible from "react-collapsible";
import {AiOutlineDownSquare} from "react-icons/ai";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { styled } from '@mui/system';
import { Chart } from "react-google-charts";
import SmallScreenSingleSelectMCQQuestion from '../smallScreen/SmallScreenSingleSelectMCQQuestion';

class SingleSelectMCQQuestion extends React.Component {

    selectOption = (optionNumber) => {
        this.props.updateQuestionAnswer(this.props.questionDetails.id, this.props.questionDetails.options[optionNumber-1].id);
    }

    renderOption = (optionName, text, bgColor, isOptionSelected) => {
        return <button className={"mx-2 my-1 transition duration-150 ease-in-out text-gray-800 px-2  text-xs  "}>
            <div className="flex flex-row w-full ...">
                {!isOptionSelected?<input type="radio"/>:<input type="radio" checked = "checked" className={"checked:bg-green-300 "}/>}
                <div  className={generalTextSize + " px-2 py-2 ..."}>{optionName}:</div>
                <div className={generalTextSize + " px-2 py-2 ..."}>
                    <h2 dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(text)}}></h2>
                </div>
            </div>
        </button>
    }

    showAccuracy = (i) => {
        if(this.props.needCompletePreview === undefined || this.props.needCompletePreview == false) {
            return <div></div>
        }
        let totalResponseCount = this.props.totalResponseCount;
        let optionResponsePercentage =
                (this.props.optionIdToOptionResponseCount[this.props.options[i].optionId]*100)/(totalResponseCount);
        return <div className='pl-3 py-1'>{isNaN(optionResponsePercentage)?"0%":optionResponsePercentage.toFixed(2) + "%"}</div>
    }

    getOptionsView = () => {
        const StyledTableCell = styled(TableCell)({
            padding: 0,
          });
        const StyledTableRow = styled(TableRow)({
            padding: 0,
          });
        let optionsJSX = [];
        let isQuestionPreviewPage = this.props.submittedQuestionDetails != null ;
        this.props.questionDetails.options.forEach((option, index) => {
            let isOptionSelected = this.props.selectedOptionId == option.id;
            let isQuestionCorrect = (isQuestionPreviewPage && this.props.selectedOptionId == this.props.questionDetails.correctOptionId);
            let bgColor = isOptionSelected?(isQuestionCorrect?" bg-green-300 ":" bg-red-300 "):" bg-white ";
            if(!isQuestionPreviewPage) {
                bgColor = isOptionSelected?" bg-blue-300 ":" bg-white ";
            }
            // question is selected.
            optionsJSX.push(
                <StyledTableRow>
                    <div className={isOptionSelected?" flex w-full justify-start bg-blue-300 hover:bg-blue-300":"flex w-full justify-start hover:bg-blue-50"} onClick= {() => this.selectOption(index+1)}>
                        {this.renderOption(index+1, option.text, bgColor, isOptionSelected)}
                    </div>
                    <StyledTableCell className=''>
                        <div className='flex justify-start'>
                            {this.showAccuracy(index)}
                            {isQuestionPreviewPage&&option.id==this.props.questionDetails.correctOptionId?<div className={generalTextSize + 'pl-2 text-green-800 text-bold '}>(Correct)</div>:""}
                            {isQuestionPreviewPage && isOptionSelected && option.id!=this.props.questionDetails.correctOptionId?<div className={generalTextSize + 'pl-2 text-red-600 text-bold '}>(InCorrect)</div>:""}
                        </div>
                    </StyledTableCell>
                </StyledTableRow>
            );
        });

     const data = [
        ["Option", "Accuracy", { role: "style" }],
        ["Option 1", 22.94, "red"], // RGB value
        ["Option 2", 39.49, "green"], // English color name
        ["Option 3", 31.3, "red"],
        ["Option 4", 1.45, "red"], // CSS-style declaration
        ["Option 5", 3.45, "red"], // CSS-style declaration
    ];
  
    const options = {
        bars: "horizontal",
      };

        return <div className='flex flex-row bg-gray-50'>
            <Table className = ' bg-white'><TableBody>{optionsJSX}</TableBody></Table>
            {isQuestionPreviewPage?
                <div className='w-5/12'>
                    <Chart className=''
                        chartType="ColumnChart"
                        width="w-full"
                        height="h-full"
                        data={data}
                        options={options}
                    />
                    <div className={generalTextSize + " py-1"}>
                        Percentage of students vs option selection
                    </div>
                </div>
               :<div/> 
            }
        </div>
    }

    showSelectedTags = () =>{
        let response=[];
        let tags=[]
        tags= [...this.props.questionDetails.tags];
        for(let i=0;i<tags.length;i++){
            response.push(
                <div className="flex flex-row ">
                    <div className="py-2 ">{JSXUtils.getTagViewJSX(tags[i].tagName)}</div>
                </div>
            )
        }
        let solutionSectiontiggerContent = <div className={'flex flex-row w-full  bg-slate-50 px-3 py-2' }>
            <div className={ generalTextSize + "  font-bold w-full "}>
                Tags on Question
            </div>
            <div className='flex justify-end'>
                <AiOutlineDownSquare size={25}/>
            </div>
        </div>
        return response.length == 0 ? <div/> : <div>
            <Collapsible 
                trigger={solutionSectiontiggerContent}
                className = "border-b-2 Collapsible__trigger">
                <div className='bg-white px-10'>
                    {response}
                </div>
            </Collapsible>
        </div>
    }

    getAnswerpreview = () =>{
        if(this.props.questionDetails.answerDescription == undefined){
            return <div>
                answer ::
            </div>
        }
        else{
            return <div>
                <div className="bg-green-600">
                    answer:: 
                </div>
                <div>
                    {this.props.questionDetails.answerDescription}
                </div>
                
            </div>
        }
    }

    getAnswerDescriptionJSX = () => {
        if(this.props.questionDetails.answerDescription == null || this.props.questionDetails.answerDescription< 2) {
            return <div className=' px-4 md:px-10 py-5 border border-slate-200 ...'>
                <p className={ generalTextSize + " flex text-justify "}>
                    <div dangerouslySetInnerHTML={{__html: "Answer Description is not yet updated by the problem author"}}></div>
                </p>
            </div>;
        }
        return <div className=' px-4 md:px-10 py-5 border border-slate-200 ...'>
            <p className={ generalTextSize + " flex text-justify "}>
                <div dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(this.props.answerDescription)}}></div>
            </p>
        </div>
    }

    getAdditionalPreview = () => {
        let solutionSectiontiggerContent = <div className={'flex flex-row w-full  bg-slate-50 px-3 py-2' }>
            <div className={ generalTextSize + "  font-bold w-full "}>
                Solution Section
            </div>
            <div className='flex justify-end'>
                <AiOutlineDownSquare size={25}/>
            </div>
        </div>
        return <div>
                    <Collapsible 
                        trigger={solutionSectiontiggerContent}
                        className = "border-b-2 Collapsible__trigger">
                        <div>
                            <p className={generalTextSize + "  text-left pl-2 md:pl-10 py-2 bg-green-50 border border-slate-200 ..."}>
                                <div className='flex justify-center'>
                                    Correct Option is : option {this.props.questionDetails.correctOptions[0] + 1}
                                </div>
                            </p>
                        </div>
                        {this.getAnswerDescriptionJSX()}
                    </Collapsible>
                </div>
    }

    render() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return <SmallScreenSingleSelectMCQQuestion
                questionDetails = {this.props.questionDetails}
                selectedOptionId = {this.props.selectedOptionId}
                updateQuestionAnswer = {this.props.updateQuestionAnswer}
                selectedOptionBackgroundColor ={this.props.selectedOptionBackgroundColor}
                needCompletePreview = {this.props.needCompletePreview}
                optionIdToOptionResponseCount = {this.props.optionIdToOptionResponseCount}
                totalResponseCount = {this.props.totalResponseCount}
                submittedQuestionDetails = {this.props.submittedQuestionDetails}
                options = {this.props.options}
            />
        }
        if(this.props.questionDetails.isEditingQuestion==undefined){}
        else{
            return(
                <div className="bg-white"  >
                    <div className = "border border-slate-300 bg-gray-100">
                        <div className={generalTextSize + " text-justify pl-2 lg:pl-6 pr-2 border border-slate-200 py-6 xl:py-6 ..."} style={{minHeight: window.innerHeight*0.10}} >
                            <h1 dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(this.props.questionDetails.questionDescription)}}></h1>
                        </div>
                    </div>
                    {this.getOptionsView()}
                    {this.getAdditionalPreview()}
                    {this.showSelectedTags()}
                    
                </div>
            )
        }
        return (
            <div className='bg-white'>
                <div className = "border border-slate-300 bg-slate-50">
                    <div className={generalTextSize + " text-justify pl-2 lg:pl-6 pr-2 border border-slate-200 py-6 xl:py-6 ..."} style={{minHeight: window.innerHeight*0.10}} >
                        <h1 dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(this.props.questionDetails.description)}}></h1>
                    </div>
                </div>
                <div className='bg-white w-full'>
                    {this.getOptionsView()}
                </div>
            </div>
        );
    }

}

export default SingleSelectMCQQuestion;
