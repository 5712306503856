import React from 'react';
import QuestionsReceiver from "../../apis/QuestionsReceiver"
import { connect } from 'react-redux';
import {updateSubmittedQuestionDetails} from '../../store/actions/solgressAction';
import SingleSelectMCQPreview from './../adminPortal/previews/SingleSelectMCQPreview';
import {JSXUtils} from "./../../utils/JSXUtils";
import {generalTextSize} from './../../constants/TextSizeConstants';
import { currentURLHost } from './../../constants/hostConfig';
import EdforcesHeader from '../../js/header/EdforcesHeader';
import { UserDetailsUtil } from '../../utils/UserDetailsUtil';
import JSONPretty from 'react-json-pretty';
import Collapsible from "react-collapsible";
import {AiOutlineDownSquare} from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";

const mapDispatchToProps = dispatch => ({
    updateSubmittedQuestionDetails: (payload) => dispatch(updateSubmittedQuestionDetails(payload))
})


const mapStateToProps = state => {
    return {
        submittedQuestionDetails: state.solgressReducer.submittedQuestionDetails
    };
}


class GeneralQuestionView extends React.Component {

    constructor(props) {
        super(props)
        this.updateQuestionAnswer = this.updateQuestionAnswer.bind(this);
    }

    updateQuestionAnswer = (questionId, optionId) => {
        let payload = {...this.props.submittedQuestionDetails};
        payload.selectedOptionId = optionId;
        this.props.updateSubmittedQuestionDetails(payload);
    }

    initializeSubmittedQuestionDetails = () => {
        const search = window.location.search;
        const responseId = new URLSearchParams(search).get('response_id');
        if(responseId === null || responseId === undefined) {
            return;
        }
        QuestionsReceiver.getSubmittedQuestion(responseId).then(submittedQuestionData=>{
            this.props.updateSubmittedQuestionDetails(submittedQuestionData.data);
        });
    }

    getUserLevelAnalysisDetails = () => {
        if(UserDetailsUtil.getUserGoogleId() != null) {
            let triggerContent =  <div className={'flex flex-row w-full  bg-slate-50 px-3 py-2'}>
                <div className={ generalTextSize + "  font-bold w-full "}>
                    Your Analysis for this question (Unformatted data for now)
                </div>
                <div className='flex justify-end'>
                    <AiOutlineDownSquare size={25}/>
                </div>
            </div>

            let triggerData =  <JSONPretty id="json-pretty" className='flex text-justify bg-white px-6 ' data={this.props.submittedQuestionDetails.userQuestionAnalyticsResponse}></JSONPretty>
            return <Collapsible 
                trigger={triggerContent}
                className = "border-b-2 Collapsible__trigger">
                {triggerData}
            </Collapsible>;
        }
        return <div/>
    }

    render() {
        if(this.props.submittedQuestionDetails === undefined){
            this.initializeSubmittedQuestionDetails();
            return  <div className='bg-white '>
                <EdforcesHeader/>
                <div className='py-20' style={{minHeight: window.innerHeight}}>
                    <ClipLoader color="blue" size="100"/>
                </div>
            </div>
        }
        const normalisedSelectedOptionId = JSXUtils.getNormalisedPreviewOptionId(this.props.submittedQuestionDetails.questionData.options, this.props.submittedQuestionDetails.selectedOptionId);
        const normalisedCorrectOptionId = JSXUtils.getNormalisedPreviewOptionId(this.props.submittedQuestionDetails.questionData.options, this.props.submittedQuestionDetails.questionData.correctOptionId);
        return (
            <div>
                <EdforcesHeader/>
                <div className = 'flex flex-row bg-indigo-50'>
                    <div className='md:w-2/12'>
                        {/* Position for ads */}
                    </div>
                    <div className='flex overflow-y-auto bg-white'>
                        <div className=" justify-between w-full bg-white  h-screen " >
                        {/* {
                            (normalisedSelectedOptionId == normalisedCorrectOptionId)
                                ?<div className={'bg-green-300  py-1 lg:py-3 ' + generalTextSize }>Yay ! That was correct</div>
                                :<div className={'bg-red-300 py-1 lg:py-3' + generalTextSize }>Oops ! You did a mistake, it's Option {normalisedCorrectOptionId+1}</div>
                        } */}
                        <SingleSelectMCQPreview
                            questionText = {this.props.submittedQuestionDetails.questionData.description}
                            options = {JSXUtils.buildMCQOptionsPreviewData(this.props.submittedQuestionDetails.questionData.options, this.props.submittedQuestionDetails.questionData.options.length)}
                            selectedOptionId = {normalisedSelectedOptionId}
                            correctOption = {normalisedCorrectOptionId}
                            answerDescription = {this.props.submittedQuestionDetails.questionData.answerDescription}
                            needCompletePreview = {true}
                            optionIdToOptionResponseCount = {this.props.submittedQuestionDetails.optionIdToOptionResponseCount}
                            totalResponseCount = {this.props.submittedQuestionDetails.totalResponses}
                            correctOptionId = {normalisedCorrectOptionId}
                            submittedQuestionDetails = {this.props.submittedQuestionDetails}
                        />
                        {this.getUserLevelAnalysisDetails()}
                    </div>
                </div>
                <div className='md:w-2/12'>
                        {/* Position for ads */}
                </div>
                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralQuestionView);
