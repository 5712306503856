import React from 'react';
import Split from "react-split";
import { connect } from 'react-redux';
import {saveQuestionSet, savePaperSet, updateGeneralInfo} from '../../store/actions/solgressAction';
import QuestionsReceiver from "../../apis/QuestionsReceiver";
import TagReceiver from "../../apis/TagReceiver";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { AiFillSetting } from "react-icons/ai";
import { BsFillPencilFill, BsPlusSquare } from "react-icons/bs";
import { AiFillTags, AiFillSafetyCertificate } from 'react-icons/ai'
import { JSXUtils } from '../../utils/JSXUtils';
import {searchTableHeaderCellCSS, clickableSearchTableBodyCellTextCSS, pagingSelectionButtonStyle,
     pagesizeOptionTextSize, generalTextSize, nonClickableSearchTableBodyCellTextCSS} from './../../constants/TextSizeConstants';
import {currentURLHost} from './../../constants/hostConfig';
import { UserDetailsUtil } from '../../utils/UserDetailsUtil';
import Pagination from '@material-ui/lab/Pagination';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import EdforcesHeader from '../header/EdforcesHeader';
import PaperAPIsConnector from "../../apis/PaperAPIsConnector";
import {MiscUtils} from "../../utils/MiscUtils";
import QuestionSetSmallScreen from './QuestionSetSmallScreen';
import TagFilterViewLarge from './TagFilter/TagFilterViewLarge';
import { VscSettings } from "react-icons/vsc";
import {MdArrowDropDown} from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import PagingSection from '../adminPortal/platformCapabilities/PagingSection';

const mapDispatchToProps = dispatch => ({
    saveQuestionSet: (payload) => dispatch(saveQuestionSet(payload)),
    savePaperSet: (payload) => dispatch(savePaperSet(payload)),
    updateGeneralInfo: (payload) => dispatch(updateGeneralInfo(payload))
})


const mapStateToProps = state => {
    return {
        questionSet: state.solgressReducer.questionSet,
        paperSet: state.solgressReducer.paperSet,
        generalInfo: state.solgressReducer.generalInfo
    };
}

class QuestionSet extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
        this.initializeQuestions = this.initializeQuestions.bind(this);
        this.openQuestionEditingViewInNewTab = this.openQuestionEditingViewInNewTab.bind(this);
    }

    getPreDecidedTags = async () => { // Workaround : In case it's linked from my created questions. We pick this tag from session storage
        if( window.sessionStorage.getItem('createdByMe')==="true" ){
            let tags = [];
            await TagReceiver.getUserResourceCreationTag(JSON.parse(sessionStorage.getItem("userDetails")).email).then( tagData=> {
                    tags.push({...tagData.data})
                }
            );
            return tags;
        }
        return [];
    }

    resetResourceCreationSessionStorage = () => {
        window.sessionStorage.setItem('createdByMe',"false");
    }

    initializeQuestions = async () => {
        let tags = await this.getPreDecidedTags();
        const search = window.location.search;
        let tagIds = [];
        tags.forEach(tag => {
            tagIds.push(tag.id);
        });

        let searchText = "";
        let searchTextQueryParam = new URLSearchParams(window.location.search).get('search_text');
        if(searchTextQueryParam!=null) {
            searchText = searchTextQueryParam;
        }
        if(typeof this.props.generalInfo != "undefined") {
            searchText = this.props.generalInfo.searchText;
        }
        await QuestionsReceiver.getAllFilteredQuestions(searchText, tagIds, [], 0, 10).then(paperData=>{
            let payload = {};
            payload.tags = tags;
            payload.suggestedTags = [];
            payload.isTagSearchActive = false;
            payload.questions = paperData.data;
            payload.searchedKey = "";
            payload.helpSectionEnabled = false;
            payload.searchCriteria = "SEARCH_BY_QUESTIONS";
            payload.currentPage = 1;
            payload.currentPageSize = 10;
            this.props.saveQuestionSet(payload);
        });

        await PaperAPIsConnector.getAllFilteredPapers("",tagIds, []).then(paperData=>{ 
            let payload = {};
            payload.tags = tags;
            payload.suggestedTags = [];
            payload.isTagSearchActive = false;
            payload.papers = paperData.data;
            payload.searchedKey = "";
            payload.helpSectionEnabled = false;
            payload.searchCriteria = "SEARCH_BY_PAPER_NAME";
            this.props.savePaperSet(payload);
        });
    }  

    openQuestionEditingViewInNewTab = (questionId) => {
       window.open(currentURLHost + 'question/upsert?question_id=' + questionId)
    }

    openQuestionSubmissionViewInNewTab = (questionId) => {
        window.open(currentURLHost + 'question/view?question_id=' + questionId);
    }

    redirectToQuestionSubmissionViewInSameTab = (questionId) => {
        window.location.href = currentURLHost + 'question/view?question_id=' + questionId
    }

    redirectToPaperSubmissionViewInNewTab = (paperId) => {
        window.open(currentURLHost + 'paper/view?paper_id=' + paperId + '&paper_instance_id='+ MiscUtils.generateUUID())
    }

    redirectToQuestionsView = () => {
        let generalInfo = {...this.props.generalInfo};
        generalInfo.isViewingQuestions = true;
        this.props.updateGeneralInfo(generalInfo);
    }

    redirectToPapersView = () => {
        let generalInfo = {...this.props.generalInfo};
        generalInfo.isViewingQuestions = false; // False means its paper
        this.props.updateGeneralInfo(generalInfo);

        let paperSet = {...this.props.paperSet};
    
    }
    
    getQuestionsTableHeaderJSX = () => {
        return <div className = ' bg-white'>
                    <div className = 'flex flex-row '>
                        <div
                             className={'border border-gray-300 l- ' + (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions ? " bg-blue-100 " : "bg-white")}
                             onClick = {this.redirectToQuestionsView}
                             size = 'small'
                             padding='none'
                        >
                            <button className=' px-2  md:px-4 md:py-3'>
                                <button className={searchTableHeaderCellCSS + (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions ? " text-bold ":"")} >
                                    Questions
                                </button>
                            </button>
                        </div>
                        <div 
                            className={'border border-gray-300 ' + (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions ? "bg-white":" bg-blue-100 ")}
                            onClick = {this.redirectToPapersView}
                            size = 'small'
                            padding = 'none'
                        >
                            <button className=' px-2  md:px-4 md:py-3'>
                                <button className={searchTableHeaderCellCSS + (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions ? "" : " text-bold ")} >
                                    Papers
                                </button>
                            </button>
                        </div>
                        <div 
                            className={'border border-gray-300 rounded-r ' + (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions ? "bg-white":" bg-white ")}
                            // onClick = {this.redirectToPapersView}
                            size = 'small'
                            padding = 'none'
                        >
                            <button className=' px-2 md:px-4 '>
                                <button className={searchTableHeaderCellCSS + (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions ? "" : " text-bold ")} >
                                    Notes
                                    <div className='text-xs text-yellow-500'>
                                        upcoming
                                    </div>
                                </button>
                            </button>
                        </div>
                        {/* <div className='flex w-full bg-gray-100 border border-1 border border-gray-300  '>

                        </div> */}
                        {/* <TableCell className='bg-white border border-gray-300 ' padding = 'none'>
                            <div className='flex w-full justify-end px-2 md:px-3 xl:px-4 py-2  ' 
                                onClick={ () =>
                                    (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions)
                                        ? window.location.href = currentURLHost + 'question/upsert'
                                        : window.location.href = currentURLHost + 'paper/new'
                                }>
                                <div className='flex flex-row ...'>
                                    <button>
                                        <BsPlusSquare size = {30} color={"blue"} />
                                    </button>
                                    <button className='text-xs md:text-sm pl-1 md:pl-2 xl:pl-3 text-blue-800 text-justify'>
                                        {typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions? "New Question" : "New Paper"}
                                    </button>
                                </div>
                            </div>
                        </TableCell> */}
                    </div>
                </div>;
    }

    getTagsDivJSX = (tags) => {
        if (tags === 0) {
            return;
        }
        let response = [];
        tags.forEach(tag=> {
            response.push(
                <span className='px-1'>
                    <span className='px-1 rounded-xl px-3 text-blue-800 text-xs' /*style={{'white-space':'nowrap'}}*/>{tag.tagName}</span>
                </span>
                )
        });
        return <div className='flex flex-row ...'>
            <div className='flex justify-top ...'>
                <span className='px-2'><AiFillTags size={15} color = {'blue'}/></span>
            </div>
            <div className=''>
                {response}
            </div>
        </div>;
    }

    getTagsDivJSX = (tags, createdBy) => {
        if (tags === 0) {
            return;
        }
        let response = [];
        tags.forEach(tag=> {
            response.push(
                <span className='px-1'>
                    <span className='px-1 rounded-xl px-3 text-blue-800 text-xs py-1' /*style={{'white-space':'nowrap'}}*/>{tag.tagName}</span>
                </span>
                )
        });
        const createdByUserName = createdBy.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()); //Replace 1st character by upper
        return <div className='flex flex-row ...'>
            <div className='flex justify-top ...'>
                <span className='px-2 py-1'><AiFillTags size={15} color = {'blue'}/></span>
            </div>
            <div className='flex flex-row px-1 bg-gray-100 '>
                <div className='px-1 rounded-xl text-blue-800 text-xs py-1' /*style={{'white-space':'nowrap'}}*/>{"Author : "}</div>
                <div>
                    <img 
                        class="rounded-full w-6 h-6 " 
                        src={createdBy.picture}
                        referrerpolicy="no-referrer"
                    />
                </div>
                <div className='py-1 px-2 text-blue-800 text-xs'>
                    {createdByUserName}
                </div>
            </div>
            <div className=''>
                {response}
            </div>
        </div>;
    }

    getQuestionEligibleIcon = (question) => {
        if(question.createdBy != null && question.createdBy == UserDetailsUtil.getUserGoogleId()) {
            return <div className='flex px-2  ...'>
                <BsFillPencilFill size={15} color ={'darkblue'} onClick={()=>this.openQuestionEditingViewInNewTab(question.id)}/>
                {question.fulfilled?<AiFillSafetyCertificate color='green' size={20} />:<div/>}
            </div>;
        }
        else {
            return <div>
                    {question.fulfilled?<AiFillSafetyCertificate color='green' size={20} />:<div/>}
                </div>
        }
    }

    getQuestionsTableBodyRowsJSX = () => {
        let tableRows = [];
        if(this.props.questionSet.questions == undefined || this.props.questionSet.questions.questions.length===0) {
            let noTableFoundRow =<TableRow className="hover:bg-slate-100">
                <TableCell>
                    <p className={"flex justify-center " + generalTextSize}>                    
                        No question found with matching filters.
                    </p>
                </TableCell>
            </TableRow>
            tableRows.push(noTableFoundRow);
            return tableRows;
        }
        this.props.questionSet.questions.questions.forEach((question, index) => {
            let newRow = <TableRow className="hover:bg-slate-100 border ">
                <TableCell >
                    <div className=' flex flex-row  '>
                        <div className='flex flex-row grow '  onClick = {()=>this.redirectToQuestionSubmissionViewInSameTab(question.id)}>
                            <div className="flex flex-col ">
                                <div className='flex flex-row cursor-pointer '>
                                    <div>
                                        <p className={nonClickableSearchTableBodyCellTextCSS}>
                                            <div className={generalTextSize} dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(question.description)}}></div>
                                        </p>
                                    </div>
                                </div>
                                <div className = "flex flex-row ...">
                                    {this.getTagsDivJSX(question.tags, question.createdBy)}
                                </div>
                            </div>
                            {this.getQuestionEligibleIcon(question)}
                        </div>
                        <div className='flex flex-row pl-4'>
                            <div className='py-1 pr-2'>
                                <button className={generalTextSize + ' bg-blue-500 text-white text-bold px-3 pt-1 text-justify rounded'} onClick={()=>this.openQuestionSubmissionViewInNewTab(question.id)}>
                                    Solve
                                </button>
                            </div>
                            {
                                (
                                    (window.sessionStorage.getItem("userDetails")!="null" && window.sessionStorage.getItem("userDetails")!=undefined) 
                                    && question.createdBy.googleId==JSON.parse(window.sessionStorage.getItem("userDetails")).googleId)
                                ? <div className='py-1'>
                                    <button className={generalTextSize + ' bg-blue-500 text-white text-bold px-3 pt-1 text-justify rounded'} onClick={()=>this.openQuestionEditingViewInNewTab(question.id)}>
                                        Edit
                                    </button>
                                </div>
                                :<div/>
                            }
                        </div>
                    </div>
                </TableCell>
            </TableRow>;
            tableRows.push(newRow);
        });
        return tableRows;
    }

    redirectToPaperSubmissionViewInSameTab = (paperId) => {
        window.location.href = currentURLHost + 'paper/view?paper_id=' + paperId + '&paper_instance_id='+ MiscUtils.generateUUID();
    }

    getPapersTableBodyRowsJSX = () => {
        let tableRows = [];
        if(this.props.paperSet.length===0) {
            let noTableFoundRow =<TableRow className="hover:bg-slate-100">
                <TableCell>
                    <p className={"flex justify-center " + generalTextSize }>                    
                        No paper found with matching filters.
                    </p>
                </TableCell>
            </TableRow>
            tableRows.push(noTableFoundRow);
            return tableRows;
        }
        this.props.paperSet.papers.forEach((paper, index) => {
            let newRow = <TableRow className="hover:bg-slate-100">
                <TableCell>
                    <div className='flex flex-row' >
                        <div className=' flex flex-col w-full ' onClick = {()=>this.redirectToPaperSubmissionViewInSameTab(paper.id)}>
                            <div className="flex flex-col w-full cursor-pointer .." >
                                <div className='flex flex-row'>
                                    <div>
                                        <p className={nonClickableSearchTableBodyCellTextCSS}>
                                            <div className={generalTextSize} dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(paper.paper_name)}}></div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className = "flex flex-row ...">
                                {this.getTagsDivJSX(paper.tags)}
                            </div>
                        </div>
                        <div className='flex flex-row pl-4'>
                            <div className='py-1 pr-2'>
                                <button className={generalTextSize + ' bg-blue-500 text-white text-bold px-3 pt-1 text-justify rounded'} onClick={()=>this.redirectToPaperSubmissionViewInNewTab(paper.id)}>
                                    Solve
                                </button>
                            </div>
                            <div className='py-1'>
                                <button className={generalTextSize + ' bg-gray-500 text-white text-bold px-3 pt-1 text-justify rounded'}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </TableCell>
            </TableRow>;
            tableRows.push(newRow);
        });
        return tableRows;
    }

    getQuestionsTableJSX = () => {
        return <div className='w-full'>
                {this.getQuestionsTableHeaderJSX()}
                <TableContainer component={Paper} className = 'w-full flex grow border' >
                    <Table aria-label="simple table">
                        <TableHead >
                            
                        </TableHead>
                        <TableBody>
                            {
                                this.props.generalInfo==undefined || this.props.generalInfo.isViewingQuestions
                                    ?this.getQuestionsTableBodyRowsJSX()
                                    :this.getPapersTableBodyRowsJSX()
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
    } 

    refreshSearch = (pageNumber, updatedPageSize) => {
        let updatedPageNumber = pageNumber;
        if(pageNumber == null) {
            updatedPageNumber = this.props.questionSet.currentPage - 1;
        }
        let tagIds = [];
        this.props.questionSet.tags.forEach(tag =>{
            tagIds.push(tag.id);
        });
        QuestionsReceiver.getAllFilteredQuestions(
            this.props.questionSet.searchedKey,
             tagIds,
             [],
             updatedPageNumber,
             updatedPageSize
        ).then(questionsData=>{
            let payload = {...this.props.questionSet};
            payload.questions = questionsData.data;
            payload.currentPage = updatedPageNumber + 1;
            payload.currentPageSize = updatedPageSize;
            this.props.saveQuestionSet(payload);
        });
        this.setState({flag : this.state.flag==undefined?true:!this.state.flag}); // Forced re-render
    }

    updateHelpSectionEnabling = (event) => {
        let payload = {...this.props.questionSet};
        payload.helpSectionEnabled = event.target.checked;
        this.props.saveQuestionSet(payload);
    }

    getHelpSectionJSX = () => {
        if(this.props.questionSet.helpSectionEnabled === false) {
            return <div/>;
        }
        return <div>
            <div className="bg-emerald-200 py-2">
                <h3 className ='text-xl'>
                    Help Section
                </h3>
            </div>
            <p className='text-lg py-2'>
                1. How to create a multiple choice question on edforces ?
            </p>
            <iframe width="420" height="345" src="https://www.youtube.com/embed/y7169jEvb-Y" className='w-full'/>
            <p className='text-lg py-2'>
                2. What does preview means here ?
            </p>
            <iframe width="420" height="345" src="https://www.youtube.com/embed/e_TxH59MclA" className='w-full'/>

            <p className='text-lg py-2'>
                3. How to add images into the questions ?
            </p>
            <iframe width="420" height="345" src="https://www.youtube.com/embed/bVKHRtafgPc" className='w-full'/>
            <p className='text-lg py-2'>
                3. What is edforces ?
            </p>
            <iframe width="420" height="345" src="https://www.youtube.com/embed/hQ8GYk9gkcE" className='w-full'/>
        </div>;
    }

    handlePageChange = (event, value) => {
        if(value<=0 || value>this.props.questionSet.pageCount) {
            return;
        }
        this.refreshSearch(value-1, this.props.questionSet.currentPageSize);
    }

    updatePageSize = (event) => {
        this.refreshSearch(0, event.target.value);
    }

    toggleLoginPopOver = () => {
        this.setState({"isPageSettingsConfigOpen" : this.state.isPageSettingsConfigOpen==undefined?true:!this.state.isPageSettingsConfigOpen});
    }

    getPagingSection = () => {
        return <PagingSection
            pageCount = {this.props.questionSet.questions.pageCount}
            currentPageNumber = {this.props.questionSet.currentPage}
            handlePageChange= {this.handlePageChange}
            currentPageSize = {this.props.questionSet.currentPageSize}
            updatePageSize ={this.updatePageSize}
            togglePageSettingPopover = {this.toggleLoginPopOver}
            isPageSettingsConfigOpen = {this.state.isPageSettingsConfigOpen}
        />
    }

    updateSearchText = () => {
        QuestionsReceiver.getAllFilteredQuestions(this.props.generalInfo.searchText, [], []).then(questionsData=>{
            let payload = {...this.props.questionSet};
            payload.questions = questionsData.data;
            payload.searchedKey = this.props.generalInfo.searchText;
            this.props.saveQuestionSet(payload);
        });
    }

    render() {
        if(MiscUtils.isUserOnSmallScreen()) {
            return <QuestionSetSmallScreen/>
        }
        if(typeof window == `undefined`){
            return <div/>;
        }
        if(this.props.questionSet === undefined){
            this.initializeQuestions();
            return <div className='bg-white '>
                <EdforcesHeader
                    updateSearchText = {this.updateSearchText}
                />
                <div className='py-20' style={{minHeight: window.innerHeight}}>
                    <ClipLoader color="blue" size="100"/>
                </div>
            </div>
        }
        this.resetResourceCreationSessionStorage();
        return <div className='bg-indigo-50 '>
            <EdforcesHeader
                updateSearchText = {this.updateSearchText}
            />
            <div className = "flex flex-row h-full bg-white">
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%]'>
                    {/* Position for ads */}
                </div>
                <div className='w-full flex grow flex-col'>
                    <div className="w-full  shadow bg-white grow ">
                    <div className='flex flex-row'>
                            <div className='w-full'>
                                <TagFilterViewLarge/>
                            </div>
                        </div>
                        <div className='flex grow bg-white' style={{minHeight: window.innerHeight*0.85}}>
                            {this.getQuestionsTableJSX()}
                        </div>
                        <div className='bg-gray-50 w-full flex justify-center grow border-2'>
                            {this.getPagingSection()}
                        </div>
                    </div>
                </div>
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%]'>
                    {/* Position for ads */}
                </div>
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSet);
