export const currentHost = "https://api.edforces.com/";
export const currentURLHost = "https://www.edforces.com/";;
export const currentGoogleLoginAPIKey = "507751144675-ds2pieq6ave04u6qv8d5esb2fpj352bd.apps.googleusercontent.com";

//Changes for high capable system (Frontend + Backend combined)
// export const currentHost = "http://localhost:8080/";
// export const currentURLHost = "http://localhost:3000/";
// export const currentGoogleLoginAPIKey = "507751144675-ohhj1kuot7abh3kuagobek74anhk016l.apps.googleusercontent.com";  

// export const currentHost = "http://localhost:8080/";
// export const currentURLHost = "http://localhost:3000/";
// export const currentGoogleLoginAPIKey = "507751144675-ohhj1kuot7abh3kuagobek74anhk016l.apps.googleusercontent.com";  


// // // Changes for low capable system
// export const currentHost = "https://api.edforces.com/";
// export const currentURLHost = "http://localhost:3000/";
// export const currentGoogleLoginAPIKey = "507751144675-ohhj1kuot7abh3kuagobek74anhk016l.apps.googleusercontent.com";  
