export const headerTextViewClass = "hover:text-indigo-800 sm:text-sm md:text-md lg:text-lg xl:text-xl text-xs px-2  md:py-4 sm:py-2 py-1 font-medium";
export const headerLoginButtonViewClass = "hover:text-indigo-800 sm:text-sm md:text-md lg:text-lg xl:text-xl text-xs pr-2  md:py-3 sm:py-1 font-medium";
export const headerLoginTextClass = "text-black text-xs md:text-sm xl:text-lg px-2 font-semibold";
export const logoTextCSS = "text-white text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-bold ";
export const searchBoxCSS = "w-full bg-white xl:h-11 lg:h-10 h-9  w-full rounded-full z-0 focus:shadow focus:outline-none border ";
export const searchBoxInputCSS = "w-full bg-white px-2 focus:outline-none ";
export const buttonBesideSearchBoxCSS = "px-2 xl:h-11 lg:h-10 h-9 transition duration-150 ease-in-out rounded  border border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-800 bg-blue-500 text-white";
export const clickableSearchTableBodyCellTextCSS = "text-xs md:text-sm xl:text-lg text-left text-indigo-800 hover:text-indigo-900 hover:underline hover:underline-offset-2 text-justify ";
export const nonClickableSearchTableBodyCellTextCSS = "text-xs md:text-sm xl:text-lg";
export const generalTextSize = "text-xs sm:text-xs md:text-sm lg:text-base 2xl:text-md ";
export const smallerTextSize = "text-xs md:text-xs lg:text-sm xl:text-base";
export const tableHeaderTextSize = "text-xs sm:text-xs md:text-sm lg:text-base 2xl:text-md ";
export const searchSuggestionTextSize = "text-xs xs:text-xs md:text-md xl:text-lg text-xs ";
export const pagesizeOptionTextSize = "py-1 text-xs lg:text-sm xl:text-md";
export const pagingSelectionButtonStyle = "border bg-white rounded outline-none";
export const searchTableHeaderCellCSS = generalTextSize + " text-bold ...";
