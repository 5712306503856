import React from 'react';
import '../App.css';
import SingleSelectMCQQuestion from "./questionSet/largeScreen/SingleSelectMCQQuestion";
import {FaSchool} from "react-icons/fa";
import { SocialIcon } from 'react-social-icons';

class AboutUs extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return <div className = "flex flex-row...">
        <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%] sm:min-w-[20%] sm:max-w-[20%]'>
            {/* Position for ads */}
        </div>
        <div className="w-full  shadow bg-white  overflow-y-scroll px-3 ">
        <div>
            <div>
                <div className='flex flex-row justify-center '>
                    <div className='text-4xl py-3  text-blue-800'>
                        Edforces
                    </div>
                    <div className='py-4 text-red-500'>
                        Beta
                    </div>
                </div>
            </div>
            <div className='px-2 pb-2 text-md text-justify italic'> 
                At Edforces, we're building the a peer to peer platform where content creators can create questions, test papers and exam mocks while the
                consumers of the content can solve the questions and papers. 
            </div>
            <div className='flex flex-row'>
            <div className='px-2 pb-2 text-md text-justify italic'> 
                In our modern education system, we've been great at providing quality contents to the students cross border geographical locations as well. Thanks to the increased internet adoption, these days students in 
                remote part of Spain can learn English from creators like Vanessa on <a href="https://www.youtube.com/channel/UCxJGMJbjokfnr2-s4_RXPxQ">youtube</a> while 
                learning German in India is just a few Megabytes away.
            </div>
            </div>
            <div className='px-2 text-md text-justify italic'> 
                We believe that learning is a continuous process of upskilling ourself and doing self evaluation. We found that there are a lot of platforms which enable
                the content creators to amplify the learnings while we hardly have any platform which enables the content consumers to self-evaluate them.
                If we map it to our schooling system, a teacher in USA can teach English to thousands of students at same time but he hardly has any tool
                to evaluate the learnings of the students. They can replicate the class room on youtube but they can't replicate the examination room. To solve
                this problem we envision a peer to peer platform where any content creator can create questions and papers while the consumers of the contents
                like students can solve the question and are provided right set of analysis which helps them improve.
            </div>
        </div>
        <div>
           <div className='flex flex-row justify-center '>
                <div className='text-3xl py-3  text-blue-800'>
                        From the founder
                    </div>
                </div>
                <div class="grid sm:grid-cols-1 md:grid-cols-1 text-justify ...">
                    <div className=' px-3 py-3 flex flex-row ...'>
                        <div  class=" w-4/12 flex justify-center rounded-full ...">
                            <img src="./../../../correct_sign.png" alt="Krishna's img" width="100" height="100"/> 
                        </div>
                        <div className='w-8/12'>
                            <div>
                                I graduated in 2018 from National Institute of Technology(NIT), Patna and have been working for as a Software developer. Coming from a remote village
                                in Bihar, I've always had a scarcity of right set of tools and infrastructure for productivity enrichment as a student/teacher. Edforces is an initiative
                                I'm taking to build a product which can give quality analysis of our strengths and scope of improvements for us as a student/teacher. Let's learn together
                                and let's grow together.
                            </div>
                            <div>
                                If you've anything to share with us, feel free to ping me on <SocialIcon url="https://www.linkedin.com/in/krishna-murari-208615ba/" height="5" width="5"/> or below contacts. 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='flex flex-col px-2'>
                    <div className='text-3xl py-4  text-blue-800 justify-center'>
                        Contact
                    </div>
                    <div className='flex flex-col text-justify pb-5'>
                        <div className='py-1'>
                            Phone(USA) : (+1) 917-576-6318
                        </div>
                        <div className='py-1'>
                            Phone(India)(whatsapp only) : (+91) 7488-093-798
                        </div>
                        <div className='py-1'>
                            Mailing Address : 3FL, 69, Graham Street, Jersey City, New Jersey, USA (zip-07307)
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%]'>
            {/* Position for ads */}
        </div>
    </div>
    }

}

export default AboutUs;
