import React from 'react';
import { connect } from 'react-redux';
import {updateNewChannelDetails} from '../../store/actions/solgressAction';
import ChannelReceiver from '../../apis/ChannelReceiver';

const mapDispatchToProps = dispatch => ({
    updateNewChannelDetails: (payload) => dispatch(updateNewChannelDetails(payload))
})


const mapStateToProps = state => {
    return {
        newChannelDetails: state.solgressReducer.newChannelDetails
    };
}

class NewChannelCreation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
        this.initializeNewChannelDetails = this.initializeNewChannelDetails.bind(this);
    }

    initializeNewChannelDetails = () => {
        let payload = {
            "channelName" : "",
            "channelDescription" : ""
        }
        this.props.updateNewChannelDetails(payload);
    }

    updateChannelName = (event) => {
        let payload = {...this.props.newChannelDetails}
        payload.channelName = event.target.value;
        this.props.updateNewChannelDetails(payload);
    }

    updateChannelDescription = (event) => {
        let payload = {...this.props.newChannelDetails};
        payload.channelDescription = event.target.value;
        this.props.updateNewChannelDetails(payload);
    }

    submitNewChannel = () => {
        ChannelReceiver.upsertChannel(this.props.newChannelDetails).then(channelData=>{
            alert("Updated the question details");
         });
    }

    render() {
        if(typeof window == `undefined`){
            return <div/>;
        }
        if(this.props.newChannelDetails === undefined){
            this.initializeNewChannelDetails();
            return <div></div>;
        }
        return <div>
            <div class="mt-4 flex flex-row w-full">
                <div className='px-4 py-2'>Channel Name</div>
                <input className="w-1/4 border border-gray-300  pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent placeholder-gray-500 text-gray-600 "
                 placeholder="Enter Channel Name" 
                 value = {this.props.newChannelDetails.channelName}  
                 onChange = {(event) => this.updateChannelName(event)}  
                 />
            </div>
            <div class="mt-4 flex flex-row w-full">
                <div className='px-4 py-2'>Description</div>
                <textarea className="w-1/3 resize bg-transparent border border-gray-300  pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 resize-none placeholder-gray-500 text-gray-600 "
                 placeholder="Provide the description of channel-name for everyone to better understand and reuse it" 
                 value = {this.props.newChannelDetails.channelDescription}
                 onChange = {(event) => this.updateChannelDescription(event)}
                 rows="5"/>
            </div>
            <div  class="mt-4 px-4 flex">
                <button className=" my-1fle transition duration-150 ease-in-out rounded  border border-gray-300 py-2 text-xs focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-800 bg-blue-300"
                    onClick={() => this.submitNewChannel()}
                >
                    <p className="text-lg px-4 ... ">
                        Submit
                    </p>
                </button>
            </div>
        </div>;
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(NewChannelCreation);
