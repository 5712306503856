import React from 'react';
import { connect } from 'react-redux';
import {saveUserDetails, updateGeneralInfo} from '../../../store/actions/solgressAction'
import GoogleLogout from 'react-google-login';
import {headerTextViewClass, logoTextCSS, headerLoginButtonViewClass, searchBoxCSS, headerLoginTextClass, searchBoxInputCSS, generalTextSize} from '../../../constants/TextSizeConstants';
import { currentURLHost } from '../../../constants/hostConfig';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { currentGoogleLoginAPIKey, currentHost } from '../../../constants/hostConfig';
import { VscSettings } from "react-icons/vsc";
import { AiOutlineSearch } from "react-icons/ai";
import { GoogleLogin } from 'react-google-login';
import {FcGoogle} from "react-icons/fc"
import TagFilterViewSmall from '../../questionSet/TagFilter/TagFilterViewSmall';
import TagFilterViewLarge from '../../questionSet/TagFilter/TagFilterViewLarge'
// import styleHeader from '../../header/styleHeader.css'
// import leftSideBar from src\js\questionSet\TagFilter\leftSideBar.css
// import leftSideBar from '../../questionSet/TagFilter/leftSideBar'
// import './TagFilter/leftSideBar.css';
import {BsClipboardPlus} from "react-icons/bs";
import {IoCreateOutline} from "react-icons/io5"
import {MdExitToApp} from "react-icons/md";

const mapDispatchToProps = dispatch => ({
    updateGeneralInfo: (payload) => dispatch(updateGeneralInfo(payload)),
    saveUserDetails :(payload) => dispatch(saveUserDetails(payload))
})


const mapStateToProps = state => {
    return {
        generalInfo: state.solgressReducer.generalInfo,
        userDetails: state.solgressReducer.userDetails
    };
}

class EdforcesHeaderLargeScreen extends React.Component {

    componentDidMount(){
        this.setState(
            {
                documentLoaded:true,
            }
        );
    }
    initializeTagFilterView = () => {
        let generalInfo = {};
        generalInfo.isTagFilterViewActive = true;
        generalInfo.isCreateContentPopupActive = false;
        generalInfo.temporarySelectedTags=[];
        generalInfo.selectedTags=[];        
        generalInfo.isViewingQuestions = true;
        generalInfo.isViewingPapers = false;
        generalInfo.isSearchActive = false;
        generalInfo.searchText = "";
        let searchTextQueryParam = new URLSearchParams(window.location.search).get('search_text');
        if(searchTextQueryParam!=null) {
            generalInfo.searchText = searchTextQueryParam;
        }
        this.props.updateGeneralInfo(generalInfo);
    }

    handleGoogleLoginResponse = (response) => {
        window.sessionStorage.setItem("userDetails", JSON.stringify(response.profileObj));
        this.props.saveUserDetails(response.profileObj);
        window.location.reload();
    }

    handleGoogleLoginFailureResponse = (response) => {
        console.log("response from login failed");
    }

    handleGoogleLogoutResponse = () => {
        window.sessionStorage.setItem("userDetails", null);
        this.props.saveUserDetails(undefined);
        window.location.reload();
    }

    toggleLoginPopOver = () => {
        // let userDetails = {...this.props.userDetails};
        let userDetails = {... JSON.parse(window.sessionStorage.getItem("userDetails"))}
        if(userDetails.isUserLoginContentPopupEnabled === null || userDetails.isUserLoginContentPopupEnabled === false) {
            userDetails["isUserLoginContentPopupEnabled"] = true;
        }
        else {
            userDetails["isUserLoginContentPopupEnabled"] = false;
        }
        
        window.sessionStorage.setItem("userDetails",  JSON.stringify(userDetails));
        this.props.saveUserDetails(userDetails);
    }
    
    activateCreateContentPopOver = () => {
        let payload = {...this.props.generalInfo};
        payload.isCreateContentPopupActive = true;
        this.props.updateGeneralInfo(payload);
    }

    inactivateCreateContentPopOver = () => {
        let payload = {...this.props.generalInfo};
        payload.isCreateContentPopupActive = false;
        this.props.updateGeneralInfo(payload);
    }

    toggleLoginPopOverWithoutReload = () => {
        let userDetails = {... JSON.parse(window.sessionStorage.getItem("userDetails"))}
        if(userDetails.isUserLoginContentPopupEnabled === null || userDetails.isUserLoginContentPopupEnabled === false) {
            userDetails["isUserLoginContentPopupEnabled"] = true;
        }
        else {
            userDetails["isUserLoginContentPopupEnabled"] = false;
        }
        window.sessionStorage.setItem("userDetails",  JSON.stringify(userDetails));
    }

    moveToMySolvedPapers = () => {
        this.toggleLoginPopOverWithoutReload();
        window.sessionStorage.setItem("solvedByMe",  true);
        window.location.href = currentURLHost + "papers/instances/me";
    }

    moveToMySolvedQuestions = () => {
        this.toggleLoginPopOverWithoutReload();
        window.sessionStorage.setItem("solvedByMe",  true);
        window.location.href = currentURLHost + "questions/instances/me";
    }

    moveToMyCreatedPapers = () => {
        this.toggleLoginPopOverWithoutReload();
        window.sessionStorage.setItem("createdByMe",  true);
        window.location.href = currentURLHost + "papers";
    }

    moveToMyCreatedQuestions = () => {
        this.toggleLoginPopOverWithoutReload();
        window.sessionStorage.setItem("createdByMe",  true);
        window.location.href = currentURLHost + "questions";
    }

    getLoginLogoutButtonJSX = () => {
        if(window.sessionStorage.getItem("userDetails") === null || window.sessionStorage.getItem("userDetails") === "null") {
            // return  <div id = "signInDiv" className='flex w-full sm:justify-end'/>;
            return <div className='bg-blue-100 rounded-full'>
                    <GoogleLogin
                        clientId = {currentGoogleLoginAPIKey}
                        // buttonText = {"Login"}
                        render={renderProps => (
                            <div className='flex flex-row  pl-2 rounded-full h-9'  onClick={renderProps.onClick} >
                                <button className=' pr-2 rounded'>
                                    <FcGoogle size={25}/>
                                </button>
                                <button className='pb-1 pr-4 rounded-r-full text-blue-800'>
                                    &nbsp;&nbsp;Login
                                </button>
                            </div>
                        )}
                        onSuccess={this.handleGoogleLoginResponse}
                        onFailure = {this.handleGoogleLoginFailureResponse}
                        cookiePolicy = 'none'
                    />
            </div>;
        } else {
            let popupContent = <div className='pt-3'>
                    <div className={'w-screen md:w-full px-2 py-2 bg-white border-2 border-blue-500 z-50 ' + generalTextSize }>
                        <div className='z-50 cursor-pointer pb-1 px-2 xl:pb-2hover:underline hover:underline-offset-2 hover:text-indigo-900' onClick={this.moveToMySolvedPapers}>My Solved Papers</div>
                        <div className='z-50 cursor-pointer pb-1 px-2 xl:pb-2 hover:underline hover:underline-offset-2 hover:text-indigo-900' onClick={this.moveToMySolvedQuestions}>My Solved Questions</div>
                        <div className='z-50 cursor-pointer pb-1 px-2 xl:pb-2 hover:underline hover:underline-offset-2 hover:text-indigo-900' onClick={this.moveToMyCreatedPapers}>My created Papers</div>
                        <div className='z-50 cursor-pointer pb-1 px-2 xl:pb-2 hover:underline hover:underline-offset-2 hover:text-indigo-900' onClick={this.moveToMyCreatedQuestions}>My Created Questions</div>
                        <div className='cursor-pointer flex justify-center' onClick={()=>this.handleGoogleLogoutResponse()}>
                            <GoogleLogout
                                clientId = {currentGoogleLoginAPIKey}
                                render={renderProps => (
                                    <div className='flex flex-row rounded h-9 pl-2 pr-3 bg-blue-500'  onClick={renderProps.onClick} >
                                        <button className='py-1 rounded'>
                                            <MdExitToApp size={25} color={'white'}/>
                                        </button>
                                        <button className=' py-1 pl-1 rounded-r-full text-white'>
                                            Signout
                                        </button>
                                    </div>
                                )}
                                buttonText="Logout"
                            />
                        </div>
                    </div>
                </div>;
            return <Popover
                isOpen={
                    (this.props.userDetails ===  undefined || this.props.userDetails == null || this.props.userDetails.isUserLoginContentPopupEnabled ===false)
                        ? false
                        : this.props.userDetails.isUserLoginContentPopupEnabled
                    }
                positions={['bottom', 'left', 'right', 'up']} // preferred positions by priority
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor={'blue'}
                      arrowSize={10}
                      arrowStyle={{ opacity: 0.7 }}
                      className='popover-arrow-container'
                      arrowClassName='popover-arrow pt-3'
                    >
                      {popupContent}
                    </ArrowContainer>
                  )}
                  onClickOutside = {() => this.toggleLoginPopOver()}  
            >
            <button onClick={() => this.toggleLoginPopOver()}>
                <img 
                    class="rounded-full w-10 h-10  md:w-12 md:h-12" 
                    src={JSON.parse(window.sessionStorage.getItem("userDetails")).imageUrl}
                    referrerpolicy="no-referrer"
                />
            </button>
          </Popover>;
        }
    }

    getCreateContentButton = () => {
        let contentButtonJSX = <button className='text-2xl  text-white ' title="Quick Custom Paper">
            <div className='flex flex-row'>
                <div className='pr-1 pt-5'>
                    <IoCreateOutline size={30} color={"white"} />
                </div>
                <div className='pt-5'>
                    Create
                </div>
            </div>
        </button>
    let popupContent = <div className={'w-screen md:w-full px-2 py-2 bg-white border-2 border-blue-500 sticky top-0 z-max ' + generalTextSize}>
        <div className='z-max cursor-pointer pb-1 px-2 xl:pb-2 hover:underline hover:underline-offset-2 hover:text-indigo-900' 
            onClick={()=>window.location.href=currentURLHost+"question/upsert"}>
            New Question
        </div>
        <div className='z-max cursor-pointer pb-1 px-2 xl:pb-2 hover:underline hover:underline-offset-2 hover:text-indigo-900'
            onClick={()=>window.location.href=currentURLHost+"paper/new"}>
            New Paper
        </div>
        <div className='z-max cursor-pointer pb-1 px-2 xl:pb-2 hover:underline hover:underline-offset-2 hover:text-indigo-900'
            onClick={()=>window.location.href=currentURLHost+"tags/new"}>
            New Tag
        </div>
    </div>;
    return <Popover
            isOpen={this.props.generalInfo.isCreateContentPopupActive}
            positions={['bottom', 'left', 'right', 'up']} // preferred positions by priority
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'blue'}
                    arrowSize={10}
                    arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container'
                    arrowClassName='popover-arrow'
                >
                    {popupContent}
                </ArrowContainer>
                )}
                onClickOutside = {() => this.inactivateCreateContentPopOver()}  
        >
            <div onClick={() => this.activateCreateContentPopOver()}>
                {contentButtonJSX}
            </div>
        </Popover>;
    }

    getSideBarHamBurger = () => {
        return <div className ={headerLoginButtonViewClass}>
            {this.getLoginLogoutButtonJSX()}
        </div>;
    }

    updateSearchText = (searchText) => {
        let generalInfo = typeof this.props.generalInfo === "undefined"?{}:{...this.props.generalInfo};
        generalInfo.searchText = searchText;
        generalInfo.isTagFilterViewActive = false;
        this.props.updateGeneralInfo(generalInfo);
        this.props.updateSearchText();
    }

    oneEnterEvent = (event) => {
        if(event.key === 'Enter') {
            this.redirectToHomePageSearch();
        }
    }

    // scrollHandler = () =>{
    //     let moving = window.pageYOffset;
    //     this.props.generalInfo.visible=(this.props.generalInfo.position > moving);
    //     this.props.generalInfo.position=moving;
    //     window.addEventListener("scroll", scrollHandler);
    //     return  window.removeEventListener("scroll", scrollHandler);

    // }
    // handleScroll = () =>{
    //     let moving = window.pageYOffset;   
    //     this.props.generalInfo.visible=(this.props.generalInfo.position > moving);
    //     this.props.generalInfo.Position=(moving);
    // }
    updateTagFilterActive = () =>{
        let payload ={...this.props.generalInfo};
        if(payload.isTagFilterViewActive==true){
            payload.isTagFilterViewActive=false;
        }
        else{
            payload.isTagFilterViewActive=true;
        }
        this.props.updateGeneralInfo(payload);
    }

    getFilterView = () => {
        // return <div>
        //     prakasj
        //     <div/>
        if(this.props.generalInfo.isTagFilterViewActive==true){
            return (
                <div className='py-20 pl-20 '>
                    {/* <TagFilterViewLarge/> */}
                </div>
            );
        }
        else{
            return <div></div>;
        }
        // return <div>
        //     <TagFilterViewLarge/>;
        // </div>
    } 
    
    redirectToHomePageSearch = () => {
        window.location.href = currentURLHost + "?search_text=" + this.props.generalInfo.searchText;
    }

    render(){
        // let generalInfo = typeof this.props.generalInfo === "undefined"?{}:{...this.props.generalInfo};
        // let position=window.pageYOffset;let visible=true;
        // generalInfo.position=position;
        // generalInfo.visible=visible;
        // // this.props.updateGeneralInfo(generalInfo);
        // window.addEventListener("scroll", this.props.handleScroll);
        // const cls = generalInfo.visible ? "visible" : "hidden";

        if(typeof this.props.generalInfo == `undefined`){
            this.initializeTagFilterView();
            return <div/>
        }
        return <div className = "  flex flex-row sticky top-0 z-max">
            
                <div className="w-full  shadow bg-blue-500">
                    <div>
                    {/* <div className='w-full bg-yellow-200'>
                        Please be gentle on us. We're still under development.
                    </div> */}
                    <div className="flex flex-row w-full"> 
                        <div className='max-w-[0%] lg:min-w-[20%] lg:max-w-[20%]'>
                            <button className={" px-10 flex justify-center py-3 md:row-span-1 row-span-2 " + logoTextCSS} onClick={() => window.location.href=currentURLHost}>
                                edforces
                            </button>
                        </div>
                        {/* <div className='flex grow  justify-center'> */}
                            <div className='w-6/12 col-span-3 py-4 rounded-full w-full '>
                                <div className='flex flex-row w-full rounded-full ...'>
                                    <div className={searchBoxCSS + ' flex w-full grow ...'}>
                                        <input 
                                            type="text" 
                                            className = {searchBoxInputCSS + " w-full px-6 rounded-l-full"}
                                            placeholder="Search here ..."
                                            value={this.props.generalInfo==null ||this.props.generalInfo.searchText==null ? "": this.props.generalInfo.searchText}
                                            onChange={(event) => this.updateSearchText(event.target.value)}
                                            onKeyDown={(event)=> this.oneEnterEvent(event)}
                                        />
                                        <div className='py-1 px-4 bg-yellow-300 rounded-r-full'>
                                            <AiOutlineSearch size={30} className = "pt-1" color={"gray"} onClick = {()=>this.redirectToHomePageSearch()}></AiOutlineSearch>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                        <button className='text-2xl pl-12 pr-10 text-white' onClick={() => window.location.href=currentURLHost+"questions"}>
                            Contents
                        </button>
                        {/* <button className='text-2xl  pr-10 text-white pb-2' title="Quick Custom Paper">
                            <div className='flex flex-row'>
                                <div className='pr-1 pt-1'>
                                    <BsClipboardPlus size={25} color={"white"} />
                                </div>
                                <div>
                                    Paper
                                </div>
                            </div>
                        </button> */}
                        {this.getCreateContentButton()}
                        <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%] py-1'>
                            <div className ={headerLoginButtonViewClass + " flex pr-4 justify-end w-full"}>
                                {this.getLoginLogoutButtonJSX()}
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EdforcesHeaderLargeScreen);
