import React from 'react';
import Split from "react-split";
import { connect } from 'react-redux';
import {updateChannelDetails} from '../../store/actions/solgressAction';
import ChannelReceiver from '../../apis/ChannelReceiver';
import {currentURLHost} from './../../constants/hostConfig';


const mapDispatchToProps = dispatch => ({
    updateChannelDetails: (payload) => dispatch(updateChannelDetails(payload))
})


const mapStateToProps = state => {
    return {
        channelDetails: state.solgressReducer.channelDetails
    };
}


class ChannelHome extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
        this.initializeChannelDetails = this.initializeChannelDetails.bind(this);
    }

    initializeChannelDetails = () => {
        ChannelReceiver.getAllChannelsSummary().then(channelData=>{
            let payload = {...this.props.channelDetails};
            payload.suggestedChannels = channelData.data;
            payload.isChannelSearchActive = false;
            this.props.updateChannelDetails(payload);
        });
    }

    updateSearchedKey = (event) => {
        let searchedKey = event.target.value;
        ChannelReceiver.getSuggestedChannels(searchedKey).then(channelData=>{
            let payload = {...this.props.channelDetails};
            payload.suggestedChannels = channelData.data;
            payload.searchedKey = searchedKey;
            payload.isChannelSearchActive = true;
            this.props.updateChannelDetails(payload);
        });
    }

    updateSelectedChannel = (channelDetail) => {
        let payload = {...this.props.channelDetails};
        payload.selectedChannel = channelDetail;
        payload.isChannelSearchActive =  false;
        this.props.updateChannelDetails(payload);   
    }

    getSuggestedChannelsJSX = () => {
        if(this.props.channelDetails.isChannelSearchActive === false) {
            return;
        }
        let items = [];
        this.props.channelDetails.suggestedChannels.forEach((channelDetail) => {
            items.push(
                <div className='text-xl py-1 border border-slate-200 hover:bg-blue-200 w-full'
                    onClick={() => this.updateSelectedChannel(channelDetail)}>
                    {channelDetail.channelName}
                </div>
            )
        });
        return items;
    }

    deactivateTagSearch = () => {
        let payload = {...this.props.channelDetails};
        payload.isChannelSearchActive = false;
        this.props.updateChannelDetails(payload);
    }

    activateTagSearch = () => {
        let payload = {...this.props.channelDetails};
        payload.isChannelSearchActive = true;
        this.props.updateChannelDetails(payload);
    }

    createNewChannel = () => {
        window.open(currentURLHost + 'channel/new')
    }

    getChannelSearchBox = () => {
        return <div className='flex flex-row justify-center z-50'>
            <div className=" py-4 px-4 w-6/12">
                {this.getSearchBoxJSX()}
            </div>
            <div>
                <div  class="mt-4 px-4 flex">
                    <button className=" my-1fle transition duration-150 ease-in-out rounded-xl  border border-gray-300 py-2 text-xs focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-800 bg-blue-300"
                        onClick={() => this.createNewChannel()}
                    >
                        <div className="text-lg px-2 ... ">
                            Create New Channel
                        </div>
                    </button>
                </div>
            </div>
        </div>;
    }

    getSearchBoxJSX = () => {
        return <div onMouseLeave={()=>this.deactivateTagSearch()} onMouseEnter={()=>this.activateTagSearch()}>
            <div class=" flex justify-center items-center pr-4 pb-5">
                <div class="relative w-full" onMouseLeave={()=>this.deactivateTagSearch()} onMouseEnter={()=>this.activateTagSearch()}>
                    <input 
                        type="text" 
                        class="bg-slate-100 h-12 w-full pr-8 pl-5 rounded-full z-0 focus:outline-none"
                        placeholder="Search channels..."
                        onChange={(event)=>this.updateSearchedKey(event)}
                    />
                    <div className='absolute w-full z-30 bg-white border boarder-black'>
                        {this.getSuggestedChannelsJSX()}
                    </div>
                </div>
            </div>
        </div>; 
    }

    getHelpSectionEnablingBox = () => {
        return <div className="flex lg:flex-row flex-col items-center pt-8 pb-2 ">
            <div className="flex flex-row...">
                <div>
                    <h4 className="text-base leading-tight text-gray-800  py-3">
                        Enable Help Section :
                    </h4>
                </div>
                <div class="flex justify-center px-2">
                    <input type="checkbox" onChange = {(event) => this.updateHelpSectionEnabling(event)}/>
                </div>
            </div>
        </div>;
    }

    getChannelNameBox = () => {
        let channelName = 'User Name';
        if (this.props.channelDetails.selectedChannel !== undefined && 
            this.props.channelDetails.selectedChannel.channelName !== undefined) {
            channelName = this.props.channelDetails.selectedChannel.channelName;
        }
        return <div className="grid bg-white text-2xl px-6 py-2 justify-items-start bg-neutral-50">
            {channelName}
        </div>;
    }

    getChannelDetailHeadersJSX = () => {
        return <div className="flex flex-wraps bg-neutral-100 rounded px-7 lg:h-20 justify-center">
            <div className ="px-6 py-6  text-xl hover:text-2xl hover:text-indigo-800 hover:underline hover:underline-offset-2 font-medium"
                onClick={() => window.location.href = currentURLHost + "questions?channel_id="+this.props.channelDetails.selectedChannel.id}>Questions</div>
            <div className ="px-6 py-6 text-xl hover:text-2xl hover:text-indigo-800 hover:underline hover:underline-offset-2 font-medium"
                onClick={() => window.location.href = currentURLHost + "channels"}>Channels</div>
            <div className ="px-6 py-6 text-xl hover:text-2xl hover:text-indigo-800 hover:underline hover:underline-offset-2 font-medium">Papers</div>
        </div>;
    }

    getChannelDetailsJSXBox = () => {
        return <div>
            this represents channel details jsx box
        </div>;
    }

    render() {
        if(typeof window == `undefined`){
            return <div/>;
        }
        if(this.props.channelDetails === undefined) {
            this.initializeChannelDetails();
            return <div/>;
        }
        return <div>
            <Split className="flex" sizes={[20,80]} >
                <div className="bg-gray-50 min-h-screen">
                    {this.getHelpSectionEnablingBox()}
                </div>
                <div>
                    {this.getChannelSearchBox()}
                    <div className = "z-0">
                        {this.getChannelNameBox()}
                        {this.getChannelDetailHeadersJSX()}
                        {this.getChannelDetailsJSXBox()}
                    </div>
                </div>
            </Split>
        </div>;
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelHome);
