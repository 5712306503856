import React from 'react';
import Split from "react-split";
import { connect } from 'react-redux';
import {saveQuestionSet, savePaperSet, updateGeneralInfo} from '../../store/actions/solgressAction';
import QuestionsReceiver from "../../apis/QuestionsReceiver";
import TagReceiver from "../../apis/TagReceiver";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { AiFillSetting } from "react-icons/ai";
import { BsFillPencilFill, BsPlusSquare } from "react-icons/bs";
import { AiFillTags, AiFillSafetyCertificate } from 'react-icons/ai'
import { JSXUtils } from '../../utils/JSXUtils';
import {searchTableHeaderCellCSS, clickableSearchTableBodyCellTextCSS, nonClickableSearchTableBodyCellTextCSS, pagingSelectionButtonStyle,
     pagesizeOptionTextSize, generalTextSize} from './../../constants/TextSizeConstants';
import {currentURLHost} from './../../constants/hostConfig';
import { UserDetailsUtil } from '../../utils/UserDetailsUtil';
import Pagination from '@material-ui/lab/Pagination';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import EdforcesHeader from '../header/EdforcesHeader';
import PaperAPIsConnector from "../../apis/PaperAPIsConnector";
import {MiscUtils} from "../../utils/MiscUtils";
import EdforcesPopupBox from '../coreCapabilities/EdforcesPopupBox';

const mapDispatchToProps = dispatch => ({
    saveQuestionSet: (payload) => dispatch(saveQuestionSet(payload)),
    savePaperSet: (payload) => dispatch(savePaperSet(payload)),
    updateGeneralInfo: (payload) => dispatch(updateGeneralInfo(payload))
})


const mapStateToProps = state => {
    return {
        questionSet: state.solgressReducer.questionSet,
        paperSet: state.solgressReducer.paperSet,
        generalInfo: state.solgressReducer.generalInfo
    };
}

class QuestionSetSmallScreen extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
        this.initializeQuestions = this.initializeQuestions.bind(this);
        this.openQuestionEditingViewInNewTab = this.openQuestionEditingViewInNewTab.bind(this);
    }

    getPreDecidedTags = async () => { // Workaround : In case it's linked from my created questions. We pick this tag from session storage
        if( window.sessionStorage.getItem('createdByMe')==="true" ){
            let tags = [];
            await TagReceiver.getUserResourceCreationTag(JSON.parse(sessionStorage.getItem("userDetails")).email).then( tagData=> {
                    tags.push({...tagData.data})
                }
            );
            return tags;
        }
        return [];
    }

    resetResourceCreationSessionStorage = () => {
        window.sessionStorage.setItem('createdByMe',"false");
    }

    initializeQuestions = async () => {
        let tags = await this.getPreDecidedTags();
        const search = window.location.search;
        let tagIds = [];
        tags.forEach(tag => {
            tagIds.push(tag.id);
        });

        await QuestionsReceiver.getAllFilteredQuestions(typeof this.props.generalInfo === "undefined"?"":this.props.generalInfo.searchText, tagIds, [], 0, 10).then(paperData=>{
            let payload = {};
            payload.tags = tags;
            payload.suggestedTags = [];
            payload.isTagSearchActive = false;
            payload.questions = paperData.data;
            payload.searchedKey = "";
            payload.helpSectionEnabled = false;
            payload.searchCriteria = "SEARCH_BY_QUESTIONS";
            payload.currentPage = 1;
            payload.currentPageSize = 10;
            this.props.saveQuestionSet(payload);
        });

        await PaperAPIsConnector.getAllFilteredPapers("",tagIds, []).then(paperData=>{ 
            let payload = {};
            payload.tags = tags;
            payload.suggestedTags = [];
            payload.isTagSearchActive = false;
            payload.papers = paperData.data;
            payload.searchedKey = "";
            payload.helpSectionEnabled = false;
            payload.searchCriteria = "SEARCH_BY_PAPER_NAME";
            this.props.savePaperSet(payload);
        });
    }  

    openQuestionEditingViewInNewTab = (questionId) => {
        window.open(currentURLHost + 'question/upsert?question_id=' + questionId);
    }

    openQuestionSubmissionViewInNewTab = (questionId) => {
        window.open(currentURLHost + 'question/view?question_id=' + questionId);
    }

    redirectToQuestionSubmissionViewInSameTab = (questionId) => {
        window.location.href = currentURLHost + 'question/view?question_id=' + questionId
    }

    redirectToQuestionsView = () => {
        let generalInfo = {...this.props.generalInfo};
        generalInfo.isViewingQuestions = true;
        this.props.updateGeneralInfo(generalInfo);
    }

    redirectToPapersView = () => {
        let generalInfo = {...this.props.generalInfo};
        generalInfo.isViewingQuestions = false; // False means its paper
        this.props.updateGeneralInfo(generalInfo);

        let paperSet = {...this.props.paperSet};
    
    }
    
    getQuestionsTableHeaderJSX = () => {
        return <TableRow className = 'border border-slate-300 bg-gray-50'>
                    <TableRow className = ''>
                        <TableCell
                             className={'border border-blue-300 ' + (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions ? "bg-blue-200" : "bg-white")}
                             onClick = {this.redirectToQuestionsView}
                             size = 'small'
                             padding='none'
                        >
                            <div className=' px-2 py-2 md:px-4 md:py-4'>
                                <p className={searchTableHeaderCellCSS} >
                                    Questions
                                </p>
                            </div>
                        </TableCell>
                        <TableCell 
                            className={'border border-blue-300 ' + (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions ? "bg-white":"bg-blue-200")}
                            onClick = {this.redirectToPapersView}
                            size = 'small'
                            padding = 'none'
                        >
                            <div className=' px-2 py-2  md:px-4 md:py-4'>
                                <p className={searchTableHeaderCellCSS} >
                                    Papers
                                </p>
                            </div>
                        </TableCell>
                        <TableCell className='flex w-full'>

                        </TableCell>
                        <TableCell className='border border-blue-300 bg-white' padding = 'none'>
                            <div className='flex w-full justify-end px-2 py-2 bg-white border ' 
                                onClick={ () =>
                                    (typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions)
                                        ? window.location.href = currentURLHost + 'question/upsert'
                                        : window.location.href = currentURLHost + 'paper/new'
                                }>
                                <div className='flex flex-row ...'>
                                    <div>
                                        <BsPlusSquare size = {30} color={"blue"} />
                                    </div>
                                    <div className='text-xs md:text-sm pl-1 md:pl-2 bg-white'>
                                        {typeof this.props.generalInfo == "undefined" || this.props.generalInfo.isViewingQuestions? "New Question" : "New Paper"}
                                    </div>
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableRow>;
    }

    getTagsDivJSX = (tags) => {
        if (tags === 0) {
            return;
        }
        let response = [];
        tags.forEach(tag=> {
            response.push(
                <span className='px-1'>
                    <span className='px-1 rounded-xl px-3 text-indigo-600 text-xs' /*style={{'white-space':'nowrap'}}*/>{tag.tagName}</span>
                </span>
                )
        });
        let fullTagViewPopupTriggerString = <div className='px-1 bg-blue-100 text-indigo-800 rounded'>
            {response.length>1? "+" + ( response.length-1 + " more"):""}
        </div>;
        
        let fullTagViewPopupContent = <div className='flex flex-row py-10 ...'>
            <div className='flex justify-top ...'>
                <span className='px-2'><AiFillTags size={25} color = {'gray'}/></span>
            </div>
            <div className='flex flex-col text-md'>
                {response}
            </div>
        </div>;

        let fullTagViewPopup = <EdforcesPopupBox
            popupModalClassName = " bg-white border  shadow flex w-screen h-fit"
            popupTriggerContentClassName = ""
            popupTriggerContent = {fullTagViewPopupTriggerString}
            postPopupContentHeaderClassName = "bg-blue-100 border-t-2 border-l-2 border-r-2 rounded-t-lg border-stone-400"
            postPopupContentHeader = "Question Tags"
            postpopupContentClassName = "bg-white border-2 border-stone-400 rounded-b-lg"
            postPopupContent = {fullTagViewPopupContent}
        />
        return <div className='flex flex-row ...'>
            <div className='flex justify-top ...'>
                <span className='px-2'><AiFillTags size={15} color = {'gray'}/></span>
            </div>
            <div className='flex flex-row'>
                <div className=''>
                    {response.length>0?response[0]:""}
                </div>
                <div>
                    {fullTagViewPopup}
                </div>
            </div>
        </div>;
    }

    getQuestionEligibleIcon = (question) => {
        if(question.createdBy != null && question.createdBy == UserDetailsUtil.getUserGoogleId()) {
            return <div className='flex px-2  ...'>
                <BsFillPencilFill size={15} color ={'darkblue'} onClick={()=>this.openQuestionEditingViewInNewTab(question.id)}/>
                {question.fulfilled?<AiFillSafetyCertificate color='green' size={20} />:<div/>}
            </div>;
        }
        else {
            return <div>
                    {question.fulfilled?<AiFillSafetyCertificate color='green' size={20} />:<div/>}
                </div>
        }
    }

    getQuestionsTableBodyRowsJSX = () => {
        let tableRows = [];
        if(this.props.questionSet.questions == undefined || this.props.questionSet.questions.questions.length===0) {
            let noTableFoundRow =<TableRow className="hover:bg-slate-100">
                <TableCell>
                    <p className={"flex justify-center " + generalTextSize}>                    
                        No question found with matching filters.
                    </p>
                </TableCell>
            </TableRow>
            tableRows.push(noTableFoundRow);
            return tableRows;
        }
        this.props.questionSet.questions.questions.forEach((question, index) => {
            let newRow = <TableRow className="hover:bg-slate-100 border ">
                <TableCell >
                    <div className=' flex flex-row  '>
                        <div className="flex flex-col ...">
                            <div className='flex flex-row'>
                                <div>
                                    <button className={nonClickableSearchTableBodyCellTextCSS + ' pt-3 pb-2 '}
                                        onClick = {()=>this.redirectToQuestionSubmissionViewInSameTab(question.id)}
                                    >
                                        <div className='text-sm text-justify' dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(question.description)}}></div>
                                    </button>
                                </div>
                                {/* <button className='text-gray-500 text-sm'>
                                    &nbsp;&nbsp;...
                                </button> */}
                            </div>
                            <div className = "flex flex-row ...">
                                {this.getTagsDivJSX(question.tags)}
                            </div>
                        </div>
                        {this.getQuestionEligibleIcon(question)}
                    </div>
                </TableCell>
            </TableRow>;
            tableRows.push(newRow);
        });
        return tableRows;
    }

    redirectToPaperSubmissionViewInSameTab = (paperId) => {
        window.location.href = currentURLHost + 'paper/view?paper_id=' + paperId + '&paper_instance_id='+ MiscUtils.generateUUID();
    }

    getPapersTableBodyRowsJSX = () => {
        let tableRows = [];
        if(this.props.paperSet.length===0) {
            let noTableFoundRow =<TableRow className="hover:bg-slate-100">
                <TableCell>
                    <p className={"flex justify-center " + generalTextSize }>                    
                        No paper found with matching filters.
                    </p>
                </TableCell>
            </TableRow>
            tableRows.push(noTableFoundRow);
            return tableRows;
        }
        this.props.paperSet.papers.forEach((paper, index) => {
            let newRow = <TableRow className="hover:bg-slate-100">
                <TableCell>
                    <div className="flex flex-col w-full...">
                        <p className={clickableSearchTableBodyCellTextCSS}
                            onClick = {()=>this.redirectToPaperSubmissionViewInSameTab(paper.id)}
                        >
                            <div dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(paper.paper_name)}}></div>
                        </p>
                    </div>
                    <div className = "flex flex-row ...">
                    {this.getTagsDivJSX(paper.tags)}
                    </div>
                </TableCell>
            </TableRow>;
            tableRows.push(newRow);
        });
        return tableRows;
    }

    getQuestionsTableJSX = () => {
        return <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            {this.getQuestionsTableHeaderJSX()}
                        </TableHead>
                        <TableBody>
                            {
                                this.props.generalInfo==undefined || this.props.generalInfo.isViewingQuestions
                                    ?this.getQuestionsTableBodyRowsJSX()
                                    :this.getPapersTableBodyRowsJSX()
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
    } 

    refreshSearch = (pageNumber, updatedPageSize) => {
        let updatedPageNumber = pageNumber;
        if(pageNumber == null) {
            updatedPageNumber = this.props.questionSet.currentPage - 1;
        }
        let tagIds = [];
        this.props.generalInfo.selectedTags.forEach(tag =>{
            tagIds.push(tag.id);
        });

        QuestionsReceiver.getAllFilteredQuestions(
            this.props.questionSet.searchedKey,
             tagIds,
             [],
             updatedPageNumber,
             updatedPageSize
        ).then(questionsData=>{
            let payload = {...this.props.questionSet};
            payload.questions = questionsData.data;
            payload.currentPage = updatedPageNumber + 1;
            payload.currentPageSize = updatedPageSize;
            this.props.saveQuestionSet(payload);
        });
        this.setState({flag : this.state.flag==undefined?true:!this.state.flag}); // Forced re-render
    }

    showSelectedTags = () => {
        let response = [];
        this.props.questionSet.tags.forEach(element => {
            response.push(
                <div className="flex flex-row justify-center ...">
                    <div className="py-4 px-2 ">{JSXUtils.getTagViewJSX(element.tagName)}</div>
                    <div className="py-2" onClick={()=>this.removeTag(element.id)}>
                        <button className="flex my-2 transition duration-150 ease-in-out rounded bg-gray-200 border border-gray-300 py-2  px-1 text-xs hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-800">
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                     fill="currentColor" className="bi bi-x-lg" viewBox="0 0 14 14">
                                    <path fill-rule="evenodd"
                                          d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                                    <path fill-rule="evenodd"
                                          d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                                </svg>
                        </button>
                    </div>
                </div>
            );
        });
        if(response.length === 0) {
            return;
        }
        return <div className="flex flex-row ...">
            <div>
                <p className={"px-6  py-6 " + generalTextSize + " leading-tight text-gray-800 "}>
                    Filter on Tags :
                </p>
            </div>
            {response}
        </div>;
    }

    removeTag = (tagId) => {
        let payload = {...this.props.questionSet};
        let tags = [...payload.tags];
        let suggestedTags = [...payload.suggestedTags];
        suggestedTags.push(tags.filter(function(tag) {return tag.id === tagId})[0]);
        tags = tags.filter(function(tag) {return tag.id !== tagId});
        payload.tags = tags;
        payload.suggestedTags = suggestedTags;
        let tagIds = [];
        tags.forEach(tag =>{
            tagIds.push(tag.id);
        });
        QuestionsReceiver.getAllFilteredQuestions(this.props.questionSet.searchedKey, tagIds, [], 0).then(questionsData=>{
            payload.currentPage = 1;
            payload.questions = questionsData.data;
            this.props.saveQuestionSet(payload);
        });
    }

    updateHelpSectionEnabling = (event) => {
        let payload = {...this.props.questionSet};
        payload.helpSectionEnabled = event.target.checked;
        this.props.saveQuestionSet(payload);
    }

    getHelpSectionJSX = () => {
        if(this.props.questionSet.helpSectionEnabled === false) {
            return <div/>;
        }
        return <div>
            <div className="bg-emerald-200 py-2">
                <h3 className ='text-xl'>
                    Help Section
                </h3>
            </div>
            <p className='text-lg py-2'>
                1. How to create a multiple choice question on edforces ?
            </p>
            <iframe width="420" height="345" src="https://www.youtube.com/embed/y7169jEvb-Y" className='w-full'/>
            <p className='text-lg py-2'>
                2. What does preview means here ?
            </p>
            <iframe width="420" height="345" src="https://www.youtube.com/embed/e_TxH59MclA" className='w-full'/>

            <p className='text-lg py-2'>
                3. How to add images into the questions ?
            </p>
            <iframe width="420" height="345" src="https://www.youtube.com/embed/bVKHRtafgPc" className='w-full'/>
            <p className='text-lg py-2'>
                3. What is edforces ?
            </p>
            <iframe width="420" height="345" src="https://www.youtube.com/embed/hQ8GYk9gkcE" className='w-full'/>
        </div>;
    }

    handlePageChange = (event, value) => {
        if(value<=0 || value>this.props.questionSet.pageCount) {
            return;
        }
        this.refreshSearch(value-1, this.props.questionSet.currentPageSize);
    }

    updatePageSize = (event) => {
        this.refreshSearch(0, event.target.value);
    }

    toggleLoginPopOver = () => {
        this.setState({"isPageSettingsConfigOpen" : this.state.isPageSettingsConfigOpen==undefined?true:!this.state.isPageSettingsConfigOpen});
    }

    getPageSettingPopupDiv = () => {
        let popupContent = <div className ='bg-gray-100 border-2 border-indigo-400 z-50 px-2'>
                <div className='flex flex-row py-1 ...'>
                    <div className={'text-gray-600 px-2' + generalTextSize}>
                        Page Number : 
                    </div>
                    <div className='px-2'>
                        <input type="number"
                            className="bg-white text-gray-600 px-1 border-2 text-center w-16 items-center "
                            name="custom-input-number"
                            min = "1"
                            max = {this.props.questionSet.pageCount}
                            value={this.props.questionSet.currentPage}
                            onChange={(event)=>this.handlePageChange(event, event.target.value)}
                        />
                    </div>
                </div>
                <div className='flex flex-row py-1 justify-left ...'>
                    <div className={'text-gray-600 px-2' + generalTextSize}>
                        Page Size : 
                    </div>
                    <div className='px-2'>
                    <select className= {pagesizeOptionTextSize + pagingSelectionButtonStyle + ' px-1'}
                        value={this.props.questionSet.currentPageSize}
                        onChange={(event => this.updatePageSize(event))}
                    >
                        <option className={pagesizeOptionTextSize} value="10">10</option>
                        <option className={pagesizeOptionTextSize} value="15">15</option>
                        <option className={pagesizeOptionTextSize} value="25">25</option>
                        <option className={pagesizeOptionTextSize} value="50">50</option>
                        <option className={pagesizeOptionTextSize} value="100">100</option>
                        <option className={pagesizeOptionTextSize} value="250">250</option>
                    </select>
                    </div>
                </div>
            </div>;
            return <Popover
                isOpen={this.state.isPageSettingsConfigOpen==undefined?false:this.state.isPageSettingsConfigOpen}
                positions={['right', 'bottom', 'left', 'up']} // preferred positions by priority
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'blue'}
                    arrowSize={10}
                    arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container'
                    arrowClassName='popover-arrow'
                    >
                    {popupContent}
                    </ArrowContainer>
                )}
                onClickOutside = {() => this.toggleLoginPopOver()}  
            >
                <div className='py-5' onClick={()=>this.toggleLoginPopOver()}>
                    <AiFillSetting  size={30} color = {'gray'}/>
                </div>
            </Popover>;
    }

    getPagingSection = () => {
        if(typeof this.props.generalInfo == "undefined" || !this.props.generalInfo.isViewingQuestions) {
            return <div/>;
        }
        let currentPageNumber = this.props.questionSet.currentPage;
        return <div className='flex flex-row flex justify-center ...'>
                <div className='px-4'>
                    <Pagination 
                        className = " text-xs md:text-sm xl:text-lg py-4 " 
                        size="large"  
                        count={this.props.questionSet.questions.pageCount==0?1:this.props.questionSet.questions.pageCount} 
                        color="primary" 
                        variant="outlined"   
                        shape="rounded"  
                        page = {currentPageNumber}
                        showFirstButton 
                        showLastButton 
                        onChange={this.handlePageChange}/>
                </div>
                <div>
                    {this.getPageSettingPopupDiv()}
                </div>
            </div>;
    }

    updateSearchText = () => {
        QuestionsReceiver.getAllFilteredQuestions(this.props.generalInfo.searchText, [], []).then(questionsData=>{
            let payload = {...this.props.questionSet};
            payload.questions = questionsData.data;
            payload.searchedKey = this.props.generalInfo.searchText;
            this.props.saveQuestionSet(payload);
        });
    }

    render() {
        if(typeof window == `undefined`){
            return <div/>;
        }
        if(this.props.questionSet === undefined){
            this.initializeQuestions();
            return <div></div>;
        }
        this.resetResourceCreationSessionStorage();
        return <div>
            <EdforcesHeader
                updateSearchText = {this.updateSearchText}
            />
            <div className = "flex flex-row...">
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%] sm:min-w-[20%] sm:max-w-[20%]'>
                    {/* Position for ads */}
                </div>
                <div className="w-full  shadow bg-white  overflow-y-scroll ">
                    {this.showSelectedTags()}
                    {this.getQuestionsTableJSX()}
                    {this.getPagingSection()}
                </div>
                <div className='max-w-[0%] lg:min-w-[15%] lg:max-w-[15%]'>
                    {/* Position for ads */}
                </div>
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSetSmallScreen);
