import React from 'react';
import {AiOutlineLike, AiOutlineDislike, AiOutlineEdit, AiFillLike, AiFillDislike} from 'react-icons/ai';
import {JSXUtils} from "../../utils/JSXUtils";
import {UserDetailsUtil} from "../../utils/UserDetailsUtil";
import TextEditor from "../adminPortal/platformCapabilities/TextEditor";
import { MiscUtils } from '../../utils/MiscUtils';
import {generalTextSize} from './../../constants/TextSizeConstants';
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

class QuestionComment extends React.Component {

    getVotingJSX = () => {
        const createdByUserName = this.props.questionDetails.createdBy.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()); //Replace 1st character by upper
        return <div className='py-2 border border-blue-300 bg-slate-50 rounded'>
            {this.props.questionDetails.isVotingDetailsRefreshing 
                ? <div className='flex justify-center grow'><ClipLoader color="blue"/></div>
                :<div className='flex flex-row '>
                    <div className='flex flex-row '>
                        {
                            this.props.questionDetails.hasUserUpvoted
                                ?<div className='pl-3' onClick={this.props.upvoteQuestion}><AiFillLike color='green' size="30"/></div>
                                :<div className='pl-3' onClick={this.props.upvoteQuestion}><AiOutlineLike color='green' size="30"/></div>
                        }
                        <div className={generalTextSize + ' pl-2 pr-2 md:pr-5 py-1'}>{this.props.questionDetails.upvoteCount}</div>
                        {
                            this.props.questionDetails.hasUserDownvoted
                                ?<div onClick={this.props.downvoteQuestion}><AiFillDislike color='red' size="30"/></div>
                                :<div onClick={this.props.downvoteQuestion}><AiOutlineDislike color='red' size="30"/></div>
                        }
                        <div className={generalTextSize + ' pl-2 py-1'}>{this.props.questionDetails.downvoteCount}</div>
                    </div>                 
                    <div className={generalTextSize +'pr-3 justify-end flex grow ..'}>
                        <div className='flex flex-row'>
                            <div className='rounded bg-slate-200 px-2 py-1'>
                                Author : 
                            </div>
                            <div className='pl-2 pr-1'>
                                <img 
                                    class="rounded-full w-8 h-8 " 
                                    src={ this.props.questionDetails.createdBy.picture}
                                    referrerpolicy="no-referrer"
                                />
                            </div>
                            <div className='py-1'>
                                {createdByUserName}
                            </div>
                        </div>
                    </div>
                    
                </div>
            }
        </div>
    }

    getCommentReplyTextJSX = (commentIndex, replyIndex) => {
        if(this.props.questionDetails.commentIndex==commentIndex && this.props.questionDetails.replyIndex==replyIndex) {
            return this.getCommentPostBox();
        }
        else {
            return <div className='flex flex-row'>
                <div>
                    <button className={generalTextSize + ' flex justify-start px-3 py-1 bg-indigo-100 rounded shrink'}
                        onClick={() => this.updateTextBoxIndex(commentIndex, replyIndex)}>reply</button>
                </div>
                {(this.props.questionDetails.commentIndexRefreshing==commentIndex && this.props.questionDetails.commentReplyIndexRefreshing==replyIndex)
                    ?<div className='flex justify-left grow pl-3'><ClipLoader color="blue" size="25"/></div>
                    :<div className='flex flex-row'>
                        {
                            this.props.questionComments[commentIndex].replies[replyIndex].hasUserUpvoted
                                ?<div className='py-1 pl-3' onClick={() => this.props.upvoteQuestionComment(commentIndex, replyIndex)}><AiFillLike color='green' size={20}/></div>
                                :<div className='py-1 pl-3' onClick={() => this.props.upvoteQuestionComment(commentIndex, replyIndex)}><AiOutlineLike color='green' size={20}/></div>
                        }
                        <div className={generalTextSize + ' pl-1 pr-2 py-1'}>{this.props.questionComments[commentIndex].replies[replyIndex].upvoteCount}</div>
                        {
                            this.props.questionComments[commentIndex].replies[replyIndex].hasUserDownvoted
                                ?<div className='py-1 ' onClick={() => this.props.downvoteQuestionComment(commentIndex, replyIndex)}><AiFillDislike color='red' size={20}/></div>
                                :<div className='py-1 ' onClick={() => this.props.downvoteQuestionComment(commentIndex, replyIndex)}><AiOutlineDislike color='red' size={20}/></div>
                        }
                        <div className={generalTextSize + ' pl-1 pr-2 py-1 '}>{this.props.questionComments[commentIndex].replies[replyIndex].downvoteCount}</div>
                    </div>
                }
            </div>;
        }
    }

    getCommentReplyJSX = (reply, commentIndex, replyIndex) => {
        if (this.props.questionDetails.commentIndexToEdit==commentIndex && this.props.questionDetails.replyIndexToEdit==replyIndex) {
            return this.getCommentPostBox();
        }
        else {
            return <div className='flex flex-start'>
                <div className='flex flex-col grow '>
                    <div className='flex flex-row'>
                        <div dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(reply.description)}}/>
                        {
                            (reply.userId == UserDetailsUtil.getUserGoogleId())
                            ?<div className='px-2 py-1'><AiOutlineEdit size={20} color='blue'
                                onClick={() => this.updateEditingCommentBoxIndex(commentIndex, replyIndex)}/>
                            </div>
                            :<div/>
                        }
                    </div>
                    {this.getCommentReplyTextJSX(commentIndex, replyIndex)}
                </div>
            </div>;
        }
    }

    getCommentRepliesJSX = (comment, commentIndex) => {
        if(comment.replies.length ==0) {
            return <div/>
        }
        let replies = [];
        comment.replies.forEach((reply, replyIndex) => {
            replies.push(
                <div className='hover:bg-blue-50 '>
                    <div className='flex flex-row py-2 px-2'>
                        <div>
                            <img 
                                class="rounded-full w-8 h-8 " 
                                src={reply.avatarUrl}
                                referrerpolicy="no-referrer"    
                            />
                        </div>
                        <div className='px-3 grow'>
                            {this.getCommentReplyJSX(reply, commentIndex, replyIndex)}
                        </div>
                    </div>
                </div>
            );
        });
        return <div className='pl-20'>
            <div className = "border border-gray-200">{replies}</div>
        </div>;
    }

    updateTextBoxIndex = (commentIndex, replyIndex) => {
        let userId = UserDetailsUtil.getUserGoogleId();
        if(userId==null) {
            toast(<div> Please login to add your comment ! </div>, {
                position : "top-right",
                autoClose: true,
                hideProgressBar: false,
                closeOnClick: true,
            });
            return;
        }
        this.refreshTextBoxData();
        this.props.updateTextBoxIndex(commentIndex, replyIndex);
    }

    updateEditingCommentBoxIndex = (commentIndexToEdit, replyIndexToEdit) => {
        let userId = UserDetailsUtil.getUserGoogleId();
        if(userId==null) {
            toast(<div> Please login to add your comment ! </div>, {
                position : "top-right",
                autoClose: true,
                hideProgressBar: false,
                closeOnClick: true,
            });
            return;
        }
        this.refreshTextBoxData();
        this.props.updateEditingCommentBoxIndex(commentIndexToEdit, replyIndexToEdit);
    }

    getCommentDetailsTextJSX = (index) => {
        return <div className='flex flex-row'>
            <div>
                <button className={ generalTextSize + ' flex justify-start px-3 py-1 bg-indigo-100 rounded shrink' }
                    onClick={() => this.updateTextBoxIndex(index, null)}>reply</button>
            </div>
            {
                (this.props.questionDetails.commentIndexRefreshing==index && this.props.questionDetails.commentReplyIndexRefreshing==null)
                ?<div><div className='flex justify-left pl-3'><ClipLoader color="blue" size={25}/></div></div>
                :<div className='flex flex-row'>
                    {
                        this.props.questionComments[index].hasUserUpvoted
                            ?<div className='py-1 pl-3'  onClick={() => this.props.upvoteQuestionComment(index, null)}><AiFillLike color='green' size={20}/></div>
                            :<div className='py-1 pl-3'  onClick={() => this.props.upvoteQuestionComment(index, null)}><AiOutlineLike color='green' size={20}/></div>
                    }
                    <div className={generalTextSize + ' pl-1 pr-2 py-1'}>{this.props.questionComments[index].upvoteCount}</div>
                    {
                        this.props.questionComments[index].hasUserDownvoted
                            ?<div className='py-1 pl-3'  onClick={() => this.props.downvoteQuestionComment(index, null)}><AiFillDislike color='red' size={20}/></div>
                            :<div className='py-1 pl-3'  onClick={() => this.props.downvoteQuestionComment(index, null)}><AiOutlineDislike color='red' size={20}/></div>
                    }
                    <div className={generalTextSize + ' pl-1 pr-2 py-1 '}>{this.props.questionComments[index].downvoteCount}</div> 
                </div>
            }
        </div>
    } 

    getCommentDetailJSX = (comment, index) => {
        if(this.props.questionDetails.commentIndexToEdit==index && this.props.questionDetails.replyIndexToEdit==null) {
            return this.getCommentPostBox();
        }
        else {
            return <div className='flex flex-start '>
                <div className='flex flex-col grow'>
                    <div className='flex flex-row '>
                        <div className = 'flex ' dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(comment.description)}}/>
                        {
                            (comment.userId == UserDetailsUtil.getUserGoogleId())
                            ?<div className='px-2 py-1'><AiOutlineEdit size={20} color='blue'
                                onClick={() => this.updateEditingCommentBoxIndex(index, null)}/>
                            </div>
                            :<div/>
                        }
                    </div>
                    {
                        (this.props.questionDetails.commentIndex==index && this.props.questionDetails.replyIndex==null)
                            ?this.getCommentPostBox()
                            :this.getCommentDetailsTextJSX(index)
                    }
                </div>
            </div>;
        }
    } 

    getCommentJSX = (comment, index) => {
        return <div className=' hover:bg-blue-50'>
            <div className='flex flex-row py-2 px-2 border border-slate-50'>
                <div>
                    <img 
                        class="rounded-full w-8 h-8 " 
                        src={comment.avatarUrl}
                        referrerpolicy="no-referrer"    
                    />
                </div>
                <div className='px-3 grow'>
                    {this.getCommentDetailJSX(comment, index)}
                </div>
            </div>
        </div>;
    }

    getCommentsJSX = () => {
        let comments = [];
        let existingComments = this.props.questionComments;
        existingComments.forEach((comment, index) => {
            comments.push(
                this.getCommentJSX(comment,index)
            );
            comments.push(this.getCommentRepliesJSX(comment, index));
        }); 
        return comments;
    }

    updateCommentBoxData = (data) => {
        let state = {...this.state};
        if(state == undefined) {
            state = {};
        }
        state.commentBoxData = data;
        this.setState(state);
    }
    
    refreshTextBoxData = () => {
        this.setState({"commentBoxData": undefined});
    }

    cancelPostingCommentBoxData = () => {
        this.refreshTextBoxData();
        this.updateTextBoxIndex(null, null);
        this.updateEditingCommentBoxIndex(null, null);
    }

    isEditingExistingComment = () => {
        return (this.props.questionDetails.commentIndexToEdit!=null);
    }

    postCommentBoxData = () => {
        if(UserDetailsUtil.getUserGoogleId()==null) {
            toast(<div> Please login to add comments ! </div>, {
                position : "top-right",
                autoClose: true,
                hideProgressBar: false,
                closeOnClick: true,
               });
            return;
        }
        if(this.state.commentBoxData=="" || this.state.commentBoxData==null) {
            return;
        }
        let comments = [...this.props.questionComments];
        if(!this.isEditingExistingComment()){
            if(this.props.questionDetails.commentIndex==null) {
                let newComment = {};
                newComment.userId = UserDetailsUtil.getUserGoogleId();
                newComment.description = this.state.commentBoxData;
                newComment.upvoteCount = 0;
                newComment.downvoteCount = 0;
                newComment.id = MiscUtils.generateUUID();
                newComment.replies = [];
                comments.push(newComment);
            } else {
                let targetComment = {... comments[this.props.questionDetails.commentIndex]}
                let newReply = {};
                newReply.userId = UserDetailsUtil.getUserGoogleId();
                newReply.description = this.state.commentBoxData;
                newReply.upvoteCount = 0;
                newReply.downvoteCount = 0;
                newReply.id = MiscUtils.generateUUID();
                let targetReplies = [... targetComment.replies];
                targetReplies.push(newReply);
                targetComment.replies = targetReplies
                comments[this.props.questionDetails.commentIndex] = targetComment;
            }
        }
        else {
            if(this.props.questionDetails.replyIndexToEdit==null) { // editing comment
                let targetComment = {... comments[this.props.questionDetails.commentIndexToEdit]};
                targetComment.description =  this.state.commentBoxData;
                comments[this.props.questionDetails.commentIndexToEdit] = targetComment;
            }
            else { //editing comment reply
                let targetComment = {... comments[this.props.questionDetails.commentIndexToEdit]};
                let targetReplies = [... targetComment.replies];
                let targetReply = {... targetReplies[this.props.questionDetails.replyIndexToEdit]};
                targetReply.description =  this.state.commentBoxData;
                targetReplies[this.props.questionDetails.replyIndexToEdit] = targetReply;
                targetComment.replies = targetReplies;
                comments[this.props.questionDetails.commentIndexToEdit] = targetComment;
            }
        }
        this.props.updateQuestionComments(comments);
        this.refreshTextBoxData();
        this.updateTextBoxIndex(null, null);
    }

    getCommentPostBox = () => {
        let currentCommentData = "";
        if(this.props.questionDetails.commentIndexToEdit != null 
            && this.props.questionDetails.replyIndexToEdit==null) {
            currentCommentData = this.props.questionComments[this.props.questionDetails.commentIndexToEdit].description
        }
        else if(this.props.questionDetails.commentIndexToEdit != null 
            && this.props.questionDetails.replyIndexToEdit != null) {
            currentCommentData = this.props.questionComments[this.props.questionDetails.commentIndexToEdit].replies[this.props.questionDetails.replyIndexToEdit].description;
        }
        if(this.state != null && this.state!=undefined && this.state.commentBoxData != undefined) {
            currentCommentData = this.state.commentBoxData;
        }
        let postButtonText = 'Post Comment';
        if(this.props.questionDetails.commentIndexToEdit != null) {
            postButtonText = 'Update Comment';
        }
        if(this.props.questionDetails.commentIndex != null) {
            postButtonText = 'Post Reply';
        }
        return <div className=' py-2'> 
            <TextEditor
                editorRef = {this.props.editorRef}
                onChange={this.updateCommentBoxData}
                data={currentCommentData}
            />
            <div className='flex flex-row'>
                <div className='px-2 py-2'>
                    <button className={generalTextSize + ' flex justify-center px-3 py-1 bg-indigo-200 rounded'} onClick={this.postCommentBoxData}>{postButtonText}</button>
                </div>
                <div className='px-2 py-2'>
                    <button className={ generalTextSize + 'flex justify-center px-3 py-1 bg-indigo-200 rounded'} onClick={this.cancelPostingCommentBoxData}>Cancel</button>
                </div>
            </div>
        </div>
    }

    getComments = () => {
        if(this.props.questionDetails.isCommentRefreshing==true) {
            return <div>Loading...</div>
        }
        return <div>
                {
                    (this.props.questionDetails.commentIndex == null 
                        && this.props.questionDetails.replyIndex == null
                        && this.props.questionDetails.commentIndexToEdit == null
                        && this.props.questionDetails.replyIndexToEdit == null)
                    ?this.getCommentPostBox()
                    :<div/>}
                {this.getCommentsJSX()}
            </div>;
    }

    render() {
        if(typeof window == `undefined` || this.props.questionComments == undefined) {
            if(typeof window != `undefined`){
                this.props.initializeQuestionComments();
            }
            return <div className='py-2 border border-blue-300 bg-slate-50 rounded flex flex-row justify-center'>
                <div className={" px-2 " + generalTextSize}>Loading Comment section</div>
                <ClipLoader color="blue" size="30"/>
            </div>
        }
        return ( 
            <div className='pt-1 sm:pt-2 pb-10'>
                {this.getVotingJSX()}
                <div className={generalTextSize + ' flex justify-start text-bold px-2 pt-2 md: pt-5 xl:pt-10'}>Comments</div>
                {this.getComments()}
            </div>
        );
    }

}

export default QuestionComment;
