import React from 'react';
import '../../App.css';
import { connect } from 'react-redux';
import {savePaperDetails} from '../../store/actions/solgressAction'
import SingleSelectMCQQuestion from "../questionSet/largeScreen/SingleSelectMCQQuestion";
import {JSXUtils} from "../../utils/JSXUtils";
import PaperAPIsConnector from "../../apis/PaperAPIsConnector";
import { PaperViewHelperUtil } from '../../utils/PaperViewHelperUtil';
import {currentURLHost} from '../../constants/hostConfig';
import Countdown from 'react-countdown';
import EdforcesHeader from '../header/EdforcesHeader';
import { generalTextSize } from '../../constants/TextSizeConstants';
import ClipLoader from "react-spinners/ClipLoader";

const mapDispatchToProps = dispatch => ({
    savePaperDetails: (payload) => dispatch(savePaperDetails(payload))
})


const mapStateToProps = state => {
    return {
        paperDetails: state.solgressReducer.paperDetails
    };
}

class PaperView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
        this.initializeSpeakerDetails = this.initializeSpeakerDetails.bind(this);
        this.moveToNextQuestion = this.moveToNextQuestion.bind(this);
        this.moveToPreviousQuestion = this.moveToPreviousQuestion.bind(this);
        this.saveQuestionOption = this.saveQuestionOption.bind(this);
        this.showQuestionPagingSection = this.showQuestionPagingSection.bind(this);
        this.changeCurrentQuestionNumber = this.changeCurrentQuestionNumber.bind(this);
    }

    initializeSpeakerDetails = () => {
        const search = window.location.search;
        const paperId = new URLSearchParams(search).get('paper_id');
        const paperInstanceId = new URLSearchParams(search).get('paper_instance_id');
        let payload = {
            "currentQuestionNumber" : 1,
            "questionStartTime" : Date.now(),
            "questionWiseTimeSpent" : {},
            "paper": {},
            "candidateResponses": {},
            "questionsMarkedForReviews": []
        };
        PaperAPIsConnector.getPaperDetails(paperId, paperInstanceId).then( paperData => {
            payload.paper = paperData.data;
            payload.questions = PaperViewHelperUtil.normalise(paperData.data);
            payload.paperStartTime = paperData.data.paperSubmissionResponse.paperStartTime;
            let isNewPaper = (paperData.data.paperSubmissionResponse.currentQuestionNumber==null); // only paper start time will be set in case of new paper
            if(!isNewPaper) {
                payload.currentQuestionNumber = paperData.data.paperSubmissionResponse.currentQuestionNumber;
                payload.questionStartTime = paperData.data.paperSubmissionResponse.questionStartTime==null?Date.now():paperData.data.paperSubmissionResponse.questionStartTime;
                payload.questionWiseTimeSpent = paperData.data.paperSubmissionResponse.questionWiseTimeSpent==null?{}:paperData.data.paperSubmissionResponse.questionWiseTimeSpent;
                payload.questionsMarkedForReviews = [...paperData.data.paperSubmissionResponse.questionsMarkedForReviews];
                payload.candidateResponses = this.buildCandidateResponse(paperData.data);
            }
            this.props.savePaperDetails(payload);
        });
    }

    buildCandidateResponse = (paperData) => {
        let candidateResponses = {};
        paperData.paperSubmissionResponse.questionSubmittedResponses.forEach ( questionResponse => {
            candidateResponses[questionResponse.questionData.id] = questionResponse.selectedOptionId;
        });
        return candidateResponses;
    }

    saveQuestionOption = (questionNumber, option) => {
        let payload = {...this.props.paperDetails};
        payload.questionOption = option;
        this.savePaperDetails(payload);
    }

    moveToPreviousQuestion = () => {
        this.changeCurrentQuestionNumber(parseInt(this.props.paperDetails.currentQuestionNumber)-1);
    }

    moveToNextQuestion = () => {
        this.changeCurrentQuestionNumber(parseInt(this.props.paperDetails.currentQuestionNumber)+1);
    }

    clearQuestionResponse = () => {
        let payload = {...this.props.paperDetails};
        let candidateResponses = {...payload.candidateResponses};
        let questionId = this.props.paperDetails.questions[this.props.paperDetails.currentQuestionNumber-1].id;
        delete candidateResponses[questionId];
        payload.candidateResponses = candidateResponses;
        this.savePaperDetails(payload);
    }

    changeCurrentQuestionNumber = (newQuestionNumber) => {
        if (newQuestionNumber < 1) {
            return;
        }
        if (newQuestionNumber > this.props.paperDetails.questions.length) {
            alert("No more questions available, Use submit button if you want to finish the test ?");
            return;
        }
        let payload = {...this.props.paperDetails};
        let currentQuestionId = this.props.paperDetails.questions[payload.currentQuestionNumber-1].id;
        let questionWiseTimeSpent = {...payload.questionWiseTimeSpent};
        let timeSpentOnQuestion = 
            (questionWiseTimeSpent.hasOwnProperty(currentQuestionId)?parseInt(questionWiseTimeSpent[currentQuestionId]):0)
            + (Date.now()-payload.questionStartTime);
        questionWiseTimeSpent[currentQuestionId] = timeSpentOnQuestion;
        payload.currentQuestionNumber = newQuestionNumber;
        payload.questionWiseTimeSpent = questionWiseTimeSpent;
        payload.questionStartTime = Date.now();
        this.savePaperDetails(payload);
    }

    updateQuestionAnswer = (questionId, optionId) => {
        let payload = {...this.props.paperDetails};     
        let candidateResponses = {...payload.candidateResponses};
        candidateResponses[questionId] = optionId;
        payload.candidateResponses = candidateResponses;
        this.savePaperDetails(payload);
    }

    markQuestionForReview = () => {
        let payload = {...this.props.paperDetails};
        let questionId = payload.questions[payload.currentQuestionNumber-1].id;

        let questionsMarkedForReviews = [...payload.questionsMarkedForReviews];
        if(questionsMarkedForReviews.includes(questionId)) {
            return;
        }
        questionsMarkedForReviews.push(questionId);
        payload.questionsMarkedForReviews = questionsMarkedForReviews;
        this.savePaperDetails(payload);
    }

    unmarkQuestionForReview = () => {
        let payload = {...this.props.paperDetails};
        let questionId = payload.questions[payload.currentQuestionNumber-1].id;

        let questionsMarkedForReviews = [...payload.questionsMarkedForReviews];
        if(!questionsMarkedForReviews.includes(questionId)) {
            return;
        }
        questionsMarkedForReviews = questionsMarkedForReviews.filter(function(item) {return item !== questionId})
        payload.questionsMarkedForReviews = questionsMarkedForReviews;
        this.savePaperDetails(payload);
    }

    savePaperDetails = (payload) => {
        const search = window.location.search;
        const paperId = new URLSearchParams(search).get('paper_id');
        const paperInstanceId = new URLSearchParams(search).get('paper_instance_id');
        const userDetails = window.sessionStorage.userDetails;
        let userEmail = null;
        if(userDetails != null && userDetails != "null" && userDetails != undefined) {
            userEmail=JSON.parse(userDetails).email;
        }
        PaperAPIsConnector.submitPaper(paperId, paperInstanceId, userEmail, true, this.props.paperDetails).then()
        this.props.savePaperDetails(payload);
    } 

    getReviewJSX = () => {
        let payload = {...this.props.paperDetails};
        let questionId = payload.questions[payload.currentQuestionNumber-1].id;
        if(payload.questionsMarkedForReviews.includes(questionId)) {
            return <div className="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer" onClick={this.unmarkQuestionForReview}>
                <p className="text-xs lg:text-sm px-1 font-medium leading-none mr-3">
                    <b>Unmark For Review</b></p>
            </div>;
        } else {
            return <div className="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer" onClick={this.markQuestionForReview}>
                <p className="text-xs lg:text-sm font-medium px-1 leading-none mr-3">
                    <b>Mark For Review</b></p>
            </div>;
        }
    }

    showQuestionPagingSection = () => {
        return <div className='w-full'>
            <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                <div className="w-full  flex items-center justify-between border-t border-gray-200">
                    <div className="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer" onClick={this.moveToPreviousQuestion}>
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.1665 4H12.8332" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M1.1665 4L4.49984 7.33333" stroke="currentColor" stroke-width="1.25"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.1665 4.00002L4.49984 0.666687" stroke="currentColor" stroke-width="1.25"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className="text-xs lg:text-sm ml-3 px-1 font-medium leading-none ">
                            <b>Save and Previous</b>
                        </p>
                    </div>
                    <div className="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer" onClick={this.clearQuestionResponse}>
                        <p className="text-xs lg:text-sm px-1 font-medium leading-none mr-3">
                            <b>Clear Response</b></p>
                    </div>
                    {this.getReviewJSX()}
                    <div className="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer" onClick={this.moveToNextQuestion}>
                        <p className="text-xs lg:text-sm px-1 font-medium leading-none mr-3">
                            <b>Save and Next</b></p>
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.1665 4H12.8332" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M9.5 7.33333L12.8333 4" stroke="currentColor" stroke-width="1.25"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9.5 0.666687L12.8333 4.00002" stroke="currentColor" stroke-width="1.25"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                    </div>
                </div>
            </div>
        </div>;
    }

    showQuestionStatus = () => {
        let questionStatuses = []
        this.props.paperDetails.questions.forEach((question, index) => {
            let bgColor = 'bg-white';
            let questionId = question.id;
            if(this.props.paperDetails.candidateResponses[questionId] != undefined) {
                bgColor = 'bg-green-400';
            }
            if(this.props.paperDetails.questionsMarkedForReviews.includes(questionId)) {
                bgColor = 'bg-yellow-300';
            }
            let className = "mx-1 my-2 transition duration-150 ease-in-out rounded " + bgColor + " border "+bgColor+" px-3 py-2 text-xs hover:"+bgColor+" focus:outline-none focus:ring-2 focus:ring-offset-2  focus:" + bgColor;
            questionStatuses.push(
                <button className={className}
                    onClick={() => this.changeCurrentQuestionNumber(index+1)}
                >
                    <p className="text-xs ...">
                        {index+1}
                    </p>
                </button>
            );
        })
        return <div className="px-6 flex flex-wrap">
            {questionStatuses}
        </div>;
    }

    submitPaper = () => {
        const search = window.location.search;
        const paperId = new URLSearchParams(search).get('paper_id');
        const paperInstanceId = new URLSearchParams(search).get('paper_instance_id');
        const userDetails = window.sessionStorage.userDetails;
        let userEmail = null;
        if(userDetails != null && userDetails != "null" && userDetails != undefined) {
            userEmail=JSON.parse(userDetails).email;
        }
        PaperAPIsConnector.submitPaper(paperId, paperInstanceId, userEmail, false, this.props.paperDetails).then( response => {
        })
        window.location.href = currentURLHost + "paper/submission/view?paper_submission_response_id=" + paperInstanceId;
    }

    getQuestionNumberHeader = ( questionNumber) => {
        let remainingTimeInMillis = (this.props.paperDetails.paper.allotted_paper_time*60*1000+this.props.paperDetails.paperStartTime-Date.now()); // For now all papers have 3 hours allowed.
        let color = "indigo-100";
        let timeBGColor = "bg-green-100"
        let textColor = "text-green-600";
        if(remainingTimeInMillis<0.05*this.props.paperDetails.paper.allotted_paper_time*60*1000){ // <5% Time remaining
            textColor = 'text-red-800';
            timeBGColor = 'bg-red-200'
        } else if(remainingTimeInMillis<0.20*this.props.paperDetails.paper.allotted_paper_time*60*1000){ // <20%time remaining
            textColor = 'text-yellow-600';
            timeBGColor = 'bg-yellow-100'
        }
        let timeBlock = <div className={"text-xs sm:text-lg md:text-xl xl:text-2xl  flex flex-row justify-start bg-" + color }>
            <div className={' pl-3 pr-1 py-1 rounded justify-start ' + timeBGColor} >
                Time Remaining : 
            </div>
            <div className= {textColor + ' py-1 pr-2 rounded font-bold justify-start ' + timeBGColor}>
                <Countdown 
                    date={Date.now() + remainingTimeInMillis}
                    daysInHours = {true}
                />
            </div>
        </div>;
        if(color === undefined) {
            color = "indigo-100";
        }
        return <div>
            {timeBlock}
             <div className="flex justify-between w-full">
                <div className={"flex flex-col lg:flex-row w-full items-start rounded shadow py-2 bg-" + color + " border"+ color}>
                    <div className={"flex flex-col md:flex-row w-full  lg:w-12/12 "+  color}>
                        <div className="text-xs sm:text-lg md:text-xl xl:text-2xl flex grow justify-between  ...">
                            <div className='w-full'>
                                Question Number : {questionNumber}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    render() {
        if(typeof window == `undefined`){
            return <div/>;
        }
        if(this.props.paperDetails === undefined) {
            this.initializeSpeakerDetails();
            return <div>
                <div className='bg-white '>
                    <EdforcesHeader/>
                    <div className='py-20' style={{minHeight: window.innerHeight}}>
                        <ClipLoader color="blue" size="100"/>
                    </div>
                </div>
            </div>;
        }
        let currentQuestionNumber =  this.props.paperDetails.currentQuestionNumber;
        let currentQuestionDetails = this.props.paperDetails.questions[currentQuestionNumber-1];
        let selectedOptionId = this.props.paperDetails.candidateResponses[currentQuestionDetails.id];
        return (
            <div>
                <EdforcesHeader/>
                <div className="flex w-full ">
                    <div className = "w-full flex flex-col sm:flex-row flex-nowrap sm:flex-wrap xl:flex-nowrap justify-center items-center md:px-32">
                        <div className="w-full lg:h-full lg:w-9/12 ">
                            <div className=" justify-between w-full " >
                                {this.getQuestionNumberHeader(currentQuestionNumber)}
                                {/* {JSXUtils.getQuestionNumberView(currentQuestionNumber)} */}
                                <SingleSelectMCQQuestion
                                    questionDetails = {currentQuestionDetails}
                                    selectedOptionId = {selectedOptionId}
                                    updateQuestionAnswer = {this.updateQuestionAnswer}
                                />
                                <div className="flex items-center justify-between w-full">
                                    <div
                                        className="flex flex-col lg:flex-row w-full items-start lg:items-center rounded bg-white shadow">
                                    </div>
                                </div>
                                <div className="bottom/ flex items-center justify-center">
                                    <div className="w-9/12 ">
                                        {this.showQuestionPagingSection()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:h-full lg:w-3/12  min-h-full border-t  bg-slate-50" style={{minHeight: window.innerHeight}}>
                            <div><b>Questions</b></div>
                            {this.showQuestionStatus()}
                            <button className={generalTextSize + " bg-blue-500 text-white mx-2 my-2 transition duration-150 ease-in-out rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-800"}
                                onClick= {() => this.submitPaper()}
                            >
                                Submit Paper
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PaperView);
