import React from 'react';
import '../../../App.css';
import {JSXUtils} from "../../../utils/JSXUtils";
import {generalTextSize} from '../../../constants/TextSizeConstants';
import Collapsible from "react-collapsible";
import {AiOutlineDownSquare} from "react-icons/ai";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { styled } from '@mui/system';
import { Chart } from "react-google-charts";
import { tagPrefixPairMap } from '../../../constants/tagPrefixPairMap';

class SmallScreenSingleSelectMCQQuestion extends React.Component {

    selectOption = (optionNumber) => {
        this.props.updateQuestionAnswer(this.props.questionDetails.id, this.props.questionDetails.options[optionNumber-1].id);
    }

    renderOption = (optionName, text, bgColor, isOptionSelected) => {
        let hoverColor = isOptionSelected?bgColor:"bg-blue-100";
        return <button className={"mx-2 my-1 transition duration-150 ease-in-out text-gray-800 px-2 py-2 text-xs hover:" + hoverColor +" " + bgColor}
            onClick= {() => this.selectOption(optionName)}
        >
            <div className="flex flex-row w-full ...">
                {!isOptionSelected?<input type="radio"/>:<input type="radio" checked = "checked" className={"checked:bg-green-300"}/>}
                <div  className={generalTextSize + " px-2 ..."}>{optionName}:</div>
                <div className={generalTextSize + " px-2 ..."}>
                    <h2 dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(text)}}></h2>
                </div>
            </div>
        </button>
    }

    showAccuracy = (i) => {
        if(this.props.needCompletePreview === undefined || this.props.needCompletePreview == false) {
            return <div></div>
        }
        let totalResponseCount = this.props.totalResponseCount;
        let optionResponsePercentage =
                (this.props.optionIdToOptionResponseCount[this.props.options[i].optionId]*100)/(totalResponseCount);
        return <div className='pl-3 py-1'>{isNaN(optionResponsePercentage)?"0%":optionResponsePercentage.toFixed(2) + "%"}</div>
    }

    getOptionsView = () => {
        const StyledTableCell = styled(TableCell)({
            padding: 0,
          });
        let optionsJSX = [];
        let isQuestionPreviewPage = this.props.submittedQuestionDetails != null ;
        this.props.questionDetails.options.forEach((option, index) => {
            let isOptionSelected = this.props.selectedOptionId == option.id;
            let isQuestionCorrect = (isQuestionPreviewPage && this.props.selectedOptionId == this.props.questionDetails.correctOptionId);
            let bgColor = isOptionSelected?(isQuestionCorrect?" bg-green-300 ":" bg-red-300 "):" bg-white ";
            if(!isQuestionPreviewPage) {
                bgColor = isOptionSelected?" bg-blue-300 ":" bg-white ";
            }
            // question is selected.
            optionsJSX.push(
                <TableRow className = 'border '>
                    <StyledTableCell className={bgColor }>
                        {this.renderOption(index+1, option.text, bgColor, isOptionSelected)}
                    </StyledTableCell>
                    <StyledTableCell className='border'>
                        <div className='flex flex-col justify-start'>
                            {this.showAccuracy(index)}
                            {isQuestionPreviewPage&&option.id==this.props.questionDetails.correctOptionId?<div className={generalTextSize + 'pl-2 text-green-800 text-bold '}>(Correct)</div>:""}
                            {isQuestionPreviewPage && isOptionSelected && option.id!=this.props.questionDetails.correctOptionId?<div className={generalTextSize + 'pl-2 text-red-600 text-bold '}>(InCorrect)</div>:""}
                        </div>
                    </StyledTableCell>
                </TableRow>
            );
        });

     const data = [
        ["Option", "Accuracy", { role: "style" }],
        ["Option 1", 22.94, "red"], // RGB value
        ["Option 2", 39.49, "green"], // English color name
        ["Option 3", 31.3, "red"],
        ["Option 4", 1.45, "red"], // CSS-style declaration
        ["Option 5", 3.45, "red"], // CSS-style declaration
    ];
  
    const options = {
        bars: "horizontal",
      };

        return <div className='bg-gray-50'>
            <Table className = ' bg-white'><TableBody>{optionsJSX}</TableBody></Table>
            {/* {isQuestionPreviewPage?
                <div className='w-full'>
                    <Chart className=''
                        chartType="ColumnChart"
                        width="w-full"
                        height="h-full"
                        data={data}
                        options={options}
                    />
                    <div className={generalTextSize + " py-1"}>
                        Percentage of students vs option selection
                    </div>
                </div>
               :<div/> 
            } */}
        </div>
    }

    showSelectedTags = () => {
        let response = [];
        for (let i = 0; i < 5; i++) {
            response.push([]);
        }
        for(let index=0;index<5;index++){
           response[index].push(
                <div>
                    {tagPrefixPairMap[index].prefix}
                </div>
            )
        }
        if(this.props.questionDetails.tags.length === 0) {
            return;
        }
        this.props.questionDetails.tags.forEach(element => {
            for(let index=0;index<5;index++){
                if(element.tagName.startsWith(tagPrefixPairMap[index]["prefix"])){
                    let prefixLength=tagPrefixPairMap[index]["prefix"].length;
                    response[index].push(
                        <div className="flex flex-row ...">
                            <div className="py-2 ">{JSXUtils.getTagViewJSX(element.tagName.substr(prefixLength))}</div>
                        </div>
                    );
                    break;
                }
            }
        });
        for(let index=0;index<5;index++){
            if(response[index].length==1){
                response[index]=[];
            }
            else{
                response[index]=(
                    <div class="flex flex-row whitespace-nowrap">
                        <div >
                           {response[index][0]}     
                        </div>
                        <div className="flex flex-row px-4">
                            {response[index].slice(1).map((element, j) => (
                            <div key={j} >
                                <div>
                                     {element}
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                )
            }
        }
        return response;
    }

    getAnswerpreview = () =>{
        if(this.props.questionDetails.answerDescription == undefined){
            return <div>
                answer ::
            </div>
        }
        else{
            return <div>
                <div className="bg-green-600">
                    answer:: 
                </div>
                <div>
                    {this.props.questionDetails.answerDescription}
                </div>
                
            </div>
        }
    }

    getAdditionalPreview = () => {
        let solutionSectiontiggerContent = <div className={'flex flex-row w-full  bg-slate-50 px-3 py-2' }>
            <div className={ generalTextSize + "  font-bold w-full "}>
                Solution Section
            </div>
            <div className='flex justify-end'>
                <AiOutlineDownSquare size={25}/>
            </div>
        </div>
        return <div>
                    <Collapsible 
                        trigger={solutionSectiontiggerContent}
                        className = "border-b-2 Collapsible__trigger">
                        <div>
                            <p className={generalTextSize + "  text-left pl-2 md:pl-10 py-2 bg-green-50 border border-slate-200 ..."}>
                                <div className='flex justify-center'>
                                    Correct Option is : option {this.props.questionDetails.correctOption + 1}
                                </div>
                            </p>
                        </div>
                        <div className=' px-4 md:px-10 py-5 border border-slate-200 ...'>
                            <p className={ generalTextSize + " flex text-justify "}>
                                <div dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(this.props.questionDetails.answerDescription)}}></div>
                            </p>
                        </div>
                    </Collapsible>
                </div>
    }

    render() {
        if(this.props.questionDetails.isEditingQuestion==undefined){}
        else{
            return(
                <div className="" >
                    <div className = "border border-slate-300 bg-gray-100">
                        <div className={generalTextSize + " text-justify pl-2 lg:pl-6 pr-2 border border-slate-200 py-6 xl:py-10 ..."}>
                            <h1 dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(this.props.questionDetails.questionDescription)}}></h1>
                        </div>
                    </div>
                  

                    {this.getOptionsView()}
                    {this.getAdditionalPreview()}
                    <div className='flex flex-col'>
                        <div>
                            applied tags: 
                        </div>
                        <div>
                            {this.showSelectedTags()}
                        </div>
                    </div>
                    
                </div>
            )
        }
        return (
            <div className='bg-white' >
                <div className = "border border-slate-300 bg-gray-100">
                    <div className={generalTextSize + " text-justify pl-2 lg:pl-6 pr-2 border border-slate-200 py-6 xl:py-10 ..."}>
                        <h1 dangerouslySetInnerHTML={{__html: JSXUtils.htmlDecode(this.props.questionDetails.description)}}></h1>
                    </div>
                </div>
                <div className='bg-white w-full'>
                    {this.getOptionsView()}
                </div>
            </div>
        );
    }

}

export default SmallScreenSingleSelectMCQQuestion;
